import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Information from "./information";
import Logo from "./logo";
import Menu from "./menu";
const MyRestaurant = () => {
	return (
		<>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Information />
				<Logo />
				<Menu />
			</Grid>
		</>
	);
};

export default MyRestaurant;
