import React, { useState, useEffect } from "react";
import { getTranslation } from "../../../../heplers/translationHelper";
import styles from "../daysOffTable/styles.module.css";
import { useSnackbar } from "notistack";
import { Box, Grid, TextField } from "@material-ui/core";
import Card from "../../../../components/Card";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useCustomerManagementStyles } from "../../../CustomerManagement/customerManagementStyles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Get_Email_URL, Post_Email_URL } from "../../../../constants/apiUrls";
import { Get, Post } from "../../../../actions";
import CardComponent from "../../../../components/Card";

const EmailTab = () => {
	const [loadingGetEmail, setLoadingGetEmail] = useState(false);
	const classes = useCustomerManagementStyles();
	const [email, setEmail] = useState();
	const [formData, setFormData] = useState();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getEmail();
	}, [email]);

	const initialValues = {
		email: email ? email : "",
	};
	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(
				getTranslation(
					"Please enter a valid email address",
					"Please enter a valid email address",
					"Please enter a valid email address"
				)
			),
	});

	const onSubmit = (values, actions) => {
		actions.setSubmitting(true);
		Post(
			values,
			Post_Email_URL,
			null,
			(res) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Updated successfully",
						"Updated successfully",
						"Updated successfully"
					),
					{
						variant: "success",
					}
				);
				setFormData(initialValues);
			},
			(err) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const getEmail = (payload) => {
		setLoadingGetEmail(true);
		Get(
			{},
			Get_Email_URL,
			null,
			(res) => {
				setLoadingGetEmail(false);
				setEmail(res?.data);
			},
			(err) => {
				setLoadingGetEmail(false);
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<div>
			<Card
				title={getTranslation(
					"Email Settings",
					"Email Settings",
					"Email Settings"
				)}
			>
				<CardComponent>
					<Formik
						initialValues={initialValues}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={(values, actions) => onSubmit(values, actions)}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							isSubmitting,
							setFieldValue,
							handleSubmit,
						}) => (
							<form onSubmit={handleSubmit}>
								<Grid
									container
									spacing={4}
									style={{
										margin: "5px",
									}}
								>
									<Grid item sm={12} md={4} lg={4}>
										<TextField
											required
											variant="outlined"
											fullWidth
											id="email"
											// label={getTranslation("Email", "Email", "Email")}
											name="email"
											type="text"
											size="small"
											value={values.email}
											onChange={handleChange}
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
										/>
									</Grid>
									<Grid item sm={12} md={4} lg={4}>
										<ButtonWithLoading
											title={getTranslation("Update", "Update", "Update")}
											type="submit"
											variant="contained"
											color="primary"
											textColor="white"
											size="medium"
											// size="large"
											loading={isSubmitting}
											// style={{ marginTop: "1rem" }}
										/>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</CardComponent>
			</Card>
		</div>
	);
};
export default EmailTab;
