import { Box, IconButton, MenuItem, Select } from "@material-ui/core";
import React from "react";
import {
  DutchLanguageIcon,
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../../components/icons";
import { getTranslation } from "../../../heplers/translationHelper";

const LanguageSelector = ({ isPublic }) => {
  return (
    <div>
      <Box>
        <Select
          style={{
            marginRight: window.innerWidth < 1000 ? "0px" : "20px",
            width: window.innerWidth < 1000 ? "100px" : "160px",
            height: "34px",
            borderRadius: "17px",
            border: isPublic ? "1px solid white" : "",
            backgroundColor: isPublic ? "white" : "",
          }}
          displayEmpty
          variant="outlined"
          value={
            localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
          }
          onChange={(e) => {
            localStorage.setItem("lang", e.target.value);
            window.location.reload(true);
          }}
        >
          <MenuItem value="en">
            <IconButton
              style={{ marginRight: window.innerWidth < 1000 ? "0px" : "10px" }}
            >
              <EnglishLanguageIcon />
            </IconButton>
            {window.innerWidth > 1000 ? "English" : null}
          </MenuItem>
          <MenuItem value="fr">
            <IconButton
              style={{ marginRight: window.innerWidth < 1000 ? "0px" : "10px" }}
            >
              <FrenchLanguageIcon />
            </IconButton>
            {window.innerWidth > 1000 ? "French" : null}
          </MenuItem>
          <MenuItem value="gr">
            <IconButton
              style={{ marginRight: window.innerWidth < 1000 ? "0px" : "10px" }}
            >
              <GermanLanguageIcon />
            </IconButton>
            {window.innerWidth > 1000 ? "German" : null}
          </MenuItem>
          <MenuItem value="dt">
							<IconButton
								style={{
									marginRight: window.innerWidth < 1000 ? "0px" : "10px",
								}}
							>
								<DutchLanguageIcon />
							</IconButton>
							{window.innerWidth > 1000
								? getTranslation("Dutch", "Dutch", "Dutch")
								: null}
						</MenuItem>
        </Select>
      </Box>
    </div>
  );
};

export default LanguageSelector;
