import { Box, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { styled } from "@material-ui/styles";
import { StyledToggleButtonGroup } from "../../../components";
import { getTranslation } from "../../../heplers/translationHelper";

export const FeaturesFilter = ({ selectedTab, setSelectedTab }) => {
	return (
		<Box display="flex" justifyContent="center">
			{" "}
			<StyledToggleButtonGroup value={selectedTab} color="success">
				<ToggleButton
					color="secondary"
					value="myRestaurant"
					onClick={() => setSelectedTab("myRestaurant")}
				>
					{getTranslation("My Restaurant", "My Restaurant", "My Restaurant")}
				</ToggleButton>
				<ToggleButton
					color="secondary"
					value="maxCapacity"
					onClick={() => setSelectedTab("maxCapacity")}
				>
					{getTranslation(
						"Maximum Capacity",
						"Maximum Capacity",
						"Maximum Capacity"
					)}
				</ToggleButton>
				<ToggleButton
					color="secondary"
					value="daysOff"
					onClick={() => setSelectedTab("daysOff")}
				>
					{getTranslation("Days Off", "Days Off", "Days Off")}
				</ToggleButton>
				<ToggleButton
					color="secondary"
					value="timeSlots"
					onClick={() => setSelectedTab("timeSlots")}
				>
					{getTranslation("Time Slots", "Time Slots", "Time Slots")}
				</ToggleButton>
				<ToggleButton
					color="secondary"
					value="email"
					onClick={() => setSelectedTab("email")}
				>
					{getTranslation("Email", "Email", "Email")}
				</ToggleButton>
				<ToggleButton
					color="secondary"
					value="iframe"
					onClick={() => setSelectedTab("iframe")}
				>
					{getTranslation("Iframe", "Iframe", "Iframe")}
				</ToggleButton>
			</StyledToggleButtonGroup>
		</Box>
	);
};
