import { Button, DialogActions, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { memo } from "react";
import { Get } from "../../../actions";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { MyDialog } from "../../../components/Dialog";
import { Delete_Reservations_URL } from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";

const DeleteReservationModal = memo(
  ({ openModal, onClose, selectedReservation, refreshReservations, params, setParams }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isDeletingReservations, setIsDeletingReservation] = useState(false);

    const handleDelete = () => {
      setIsDeletingReservation(true);
      Get(
        { id: selectedReservation?.id },
        Delete_Reservations_URL,
        null,
        (resp) => {
          setIsDeletingReservation(false);
          refreshReservations();
          enqueueSnackbar(
            getTranslation(
              "Deleted Successfully",
              "Deleted Successfully",
              "Deleted Successfully"
            ),
            {
              variant: "success",
            }
          );
          setParams({ ...params })
          // setParams({ ...params, selectedDate: new Date(params.selectedDate) });
        },
        (err) => {
          setIsDeletingReservation(false);
          enqueueSnackbar(
            err.statusText
              ? err.statusText
              : getTranslation(
                "Failed to delete!",
                "Failed to delete!",
                "Failed to delete!"
              ),
            {
              variant: "error",
            }
          );
        }
      );
    };

    useEffect(() => {
      if (!isDeletingReservations) {
        onClose(false);
      }
    }, [isDeletingReservations, onClose]);

    return (
      <MyDialog
        open={openModal}
        onClose={() => onClose(false)}
        title={getTranslation(
          "Delete Reservation",
          "Delete Reservation",
          "Delete Reservation"
        )}
      >
        <div>
          <Typography>
            {getTranslation(
              "Are you sure you want to delete?",
              "Are you sure you want to delete?",
              "Are you sure you want to delete?"
            )}
          </Typography>
        </div>
        <DialogActions>
          <ButtonWithLoading
            title={getTranslation("Delete", "Delete", "Delete")}
            loading={isDeletingReservations}
            onClick={handleDelete}
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </MyDialog>
    );
  }
);
export default DeleteReservationModal;
