import React from "react";
import TransparentLogoFile from "../assests/images/restobizLogo.png";
import TransparentLogoWhite from "../assests/images/RestoLogo1.1.png";

export const TransparentLogo = (props) => {
	return (
		<img
			alt="Logo"
			src={TransparentLogoFile}
			{...props}
			style={{ width: "153%", marginLeft: "-2em" }}
		/>
	);
};

export const Logo = (props) => {
	return (
		<img
			alt="Logo"
			src={TransparentLogoWhite}
			style={{ width: "100%", height: "110%" }}
			{...props}
		/>
	);
};
