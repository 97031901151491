import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	AddTable,
	DayTimeSelection,
	MaxCapacityTable,
} from "./components/maxCapacity";
import { useDaysOffStyles } from "./components/daysOffTable/daysOffStyles";
import DaysOffTab from "./components/daysOffTable/daysOffTab";
import DaysOffTable from "./components/daysOffTable/daysOffTable";
import TimeSlotTab from "./components/timeSlot/timeSlot";
import { FeaturesFilter } from "./components/featuresFilter";
import MaxCapacity from "./components/maxCapacity/maxCapacity";
import { Helmet } from "react-helmet";
import { getTranslation } from "../../heplers/translationHelper";
import EmailTab from "./components/email/emailTab";
import IframeTab from "./components/iframe/iframe";
import MyRestaurant from "./components/myRestaurant/myRestaurant";

export const TableManagement = () => {
	const classes = useDaysOffStyles();
	const history = useHistory();
	const params = useParams();

	const selectedTab = params.tab || "myRestaurant";

	const handleChange = (tab) => {
		history.push(`/settings/table-management/${tab}`);
	};

	return (
		<>
			<Helmet>
				<title>{getTranslation("Settings", "Settings", "Settings")}</title>
			</Helmet>
			<FeaturesFilter selectedTab={selectedTab} setSelectedTab={handleChange} />
			{selectedTab === "myRestaurant" && <MyRestaurant />}
			{selectedTab === "maxCapacity" && <MaxCapacity />}
			{selectedTab === "daysOff" && <DaysOffTab />}
			{selectedTab === "timeSlots" && <TimeSlotTab />}
			{selectedTab === "email" && <EmailTab />}
			{selectedTab === "iframe" && <IframeTab />}
		</>
	);
};
