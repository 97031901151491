import React, { useEffect, useState } from "react";
import GlobalStyles from "./components/GlobalStyles";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import Auth from "./components/Auth";
import { getLanguage } from "./actions";
import { Box, CircularProgress } from "@material-ui/core";
const App = () => {
  const [loadingTranslation, setLoadingTranslation] = useState(true)
  
  useEffect(() => {
    getUpdatedTranslation();
  }, [])
  const getUpdatedTranslation = () => {

    if(localStorage.getItem("lang")===null){
      localStorage.setItem("lang","fr")
    }

    getLanguage(
      resp => {
        
        if(localStorage.getItem("dictionary")!=JSON.stringify(resp.data))
         { 
          localStorage.setItem("dictionary", JSON.stringify(resp.data));
          window.location.reload();
       }
      setLoadingTranslation(false)
      },
      error => {
        setLoadingTranslation(false)
      }
    );

  }
  return  loadingTranslation ? <Box display= "flex"
  alignItems= "center"
  justifyContent= "center"
  flexDirection= "column"> 
  <CircularProgress
    style={{ margin: "auto" }}
  /></Box> :( 
  
    <React.Fragment>
      <GlobalStyles />
      <BrowserRouter>
    
      <Routes />

      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
