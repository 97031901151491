import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { format } from "../../../../heplers/format";
import DateRangeSelectorModal from "./dateRangeSelectorModal";
import { useDaysOffStyles } from "./daysOffStyles";
import { DatePicker } from "../../../../components/DatePicker";
import { getTranslation } from "../../../../heplers/translationHelper";
import styles from "./styles.module.css";

const DaysRangeCard = ({ params, setParams }) => {
  const classes = useDaysOffStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Card>
        <CardContent>
        <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <div className={styles.textCont}>
            <Typography color="primary" variant="h5">
              {getTranslation("From", "From", "From")}:{" "}
            </Typography>
            <DatePicker
              className={classes.datePicker}
              size="small"
              value={params.from}
              onChange={(val) => {
                setParams({ ...params, from: new Date(val) });
              }}
              id="from"
              name="from"
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <div className={styles.textCont}>
            <Typography color="primary" variant="h5">
              {getTranslation("To", "To", "To")}:{" "}
            </Typography>
            <DatePicker
              className={classes.datePicker}
              size="small"
              value={params.to}
              onChange={(val) => {
                setParams({ ...params, to: new Date(val) });
              }}
              id="to"
              name="to"
            />
          </div>
        </Grid>

      </Grid>
        </CardContent>
      </Card>

      <br/>
     
      <DateRangeSelectorModal
        openModal={openModal}
        onClose={setOpenModal}
        params={params}
        setParams={setParams}
      />
    </div>
  );
};

export default DaysRangeCard;
