import { makeStyles } from "@material-ui/core";

export const useReservationStyles = makeStyles((theme) => ({
  container: {
    margin: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalContent: {
    width: "45%",
    maxWidth: "45rem",
  },
  datePicker: {
    marginBottom: "1rem",
  },
  inputConts: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 1fr))",
    gap: "1rem",
    rowGap: "1rem",
  },
  radio: {
    "&.Mui-checked": {
      color: "rgba(255, 138, 0, 0.71)",
    },
  },
  submitBtn: {
    marginRight: "1rem",
  },
  btnWrapper: {
    marginTop: "1rem",
  },
  modal: {
    width: "30rem",
    padding: "1rem",
  },
  tabs: {
    width: "27rem",
  },
  loaderCont: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "2000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
