import {
	makeStyles,
	Step,
	StepConnector,
	StepLabel,
	Stepper,
	withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import { getTranslation } from "../../../../heplers/translationHelper";
import PersonalInfoForm from "./personalInfoForm";
import { useSnackbar } from "notistack";
import SuccessMsgModal from "./successMsgModal";
import { Post } from "../../../../actions";
import ReservationFormOne from "./reservationFormOne";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import PropTypes from "prop-types";
import reactRouterDom, { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import theme from "../../../../theme/index";

const ReservationForm = ({
	isEdit,
	selectedData,
	params,
	setParams,
	onCloseDialog,
	classes,
	refreshReservations,
	onlineReservationRefresh,
	disabled,
}) => {
	if (typeof classes == "undefined") {
		classes = userReservationStyles({
			primaryColor: theme.palette.primary.main,
			secondaryColor: "#ffffff",
		});
	}
	const { enqueueSnackbar } = useSnackbar();
	const [activeStep, setActiveStep] = useState(0);
	const [showSuccessMsg, setShowSuccessMsg] = useState(false);
	const [updateReservation, setUpdateReservation] = useState();
	const location = useLocation();
	const dispatch = useDispatch();

	let adminn = location.pathname.includes("/public") ? false : true;
	const [isAdmin, SetAdmin] = useState(adminn);

	const checklocation = () => {
		let admin = location.pathname.includes("/public") ? false : true;
		SetAdmin(admin);
	};

	useEffect(() => {
		checklocation();
		dispatch({ type: "SETADMIN", payload: isAdmin });
	}, []);

	const [selectedReservation, setSelectedReservation] = useState({
		persons: 1,
		reservationDate: new Date(),
		purposeOfReservation: 0,
		timeSlotId: "",
		fullName: "",
		title: "",
		email: "",
		phone: "",
		subscribeForNews: false,
		haveSmallKids: false,
		kids: 0,
		acceptTerms: false,
		allergies: "",
		startTime: "",
		timeSlots: [],
	});

	const steps = [
		getTranslation("Visiting Info", "Visiting Info", "Visiting Info"),
		getTranslation("Personal Info", "Personal Info", "Personal Info"),
	];

	const handleCloseSuccessMsg = () => {
		setShowSuccessMsg(false);
		if (onlineReservationRefresh) onlineReservationRefresh();
		else onCloseDialog(false);
		// window.location.reload();
	};

	const addReservation = (payload, url, actions) => {
		// setUpdateReservation(payload);
		Post(
			payload,
			url,
			null,
			(resp) => {
				setUpdateReservation(resp?.data);
				actions.setSubmitting(false);
				setShowSuccessMsg(true);
				enqueueSnackbar(
					isEdit
						? getTranslation(
								"Reservation updated successfully",
								"Reservation updated successfully",
								"Reservation updated successfully"
						  )
						: getTranslation(
								"Reservation created successfully",
								"Reservation created successfully",
								"Reservation created successfully"
						  ),
					{
						variant: "success",
					}
				);
				// setActiveStep(0);
				// if(onCloseDialog)
				// onCloseDialog(false);
				// setParams({
				//   ...params,
				//   selectedDate: new Date(payload?.reservationDate),
				// });
				if (refreshReservations) refreshReservations();
			},
			(err) => {
				if (!err) return;
				actions.setSubmitting(false);
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleStepChange = (step) => {
		if (!selectedReservation.timeSlotId) {
			enqueueSnackbar(
				getTranslation(
					" Please select a time slot",
					" Please select a time slot ",
					" Please select a time slot "
				),
				{
					variant: "error",
				}
			);
			return;
		}
		setActiveStep(step);
	};

	const useStyles = makeStyles(() => ({
		root: {
			"& .MuiStepIcon-active": { color: "theme.palette.primary.main" },
			"& .MuiStepIcon-completed": { color: "green" },
			"& .Mui-disabled .MuiStepIcon-root": { color: "cyan" },
		},
	}));

	const c = useStyles();

	return (
		<div className={isEdit ? classes.editFormCard : classes.formCard}>
			{!isEdit && (
				<div className={classes.stepperWrapper}>
					<Stepper className={classes.root} activeStep={activeStep}>
						{steps.map((step, index) => (
							<Step key={step} onClick={() => handleStepChange(index)}>
								<StepLabel>{step}</StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
			)}
			{activeStep === 0 && (
				<ReservationFormOne
					isEdit={isEdit}
					selectedReservation={selectedReservation}
					setSelectedReservation={(values) => {
						setSelectedReservation(values);
					}}
					setActiveStep={setActiveStep}
					activeStep={activeStep}
					addReservation={addReservation}
					selectedData={selectedData}
					classes={classes}
					defaultPrimaryColor={theme.palette.primary.main}
					disabled={disabled}
				/>
			)}
			{activeStep === 1 && (
				<PersonalInfoForm
					selectedReservation={selectedReservation}
					setSelectedReservation={(values) => {
						setSelectedReservation(values);
					}}
					setActiveStep={setActiveStep}
					addReservation={addReservation}
					showSuccessMsg={showSuccessMsg}
					setShowSuccessMsg={setShowSuccessMsg}
					classes={classes}
				/>
			)}
			<SuccessMsgModal
				openModal={showSuccessMsg}
				data={isEdit ? updateReservation : selectedReservation}
				handleClose={handleCloseSuccessMsg}
			/>
		</div>
	);
};

export default ReservationForm;
