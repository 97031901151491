import { Alert } from "@material-ui/lab";
import { MyDialog } from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import { ReservationForm } from "../../public/component/ReservationForm";
import { AddAlert, NotificationImportant, NotificationsActive } from "@material-ui/icons";

const EditReservationModal = ({
  openModal,
  onClose,
  isEdit,
  selectedReservation,
  params,
  setParams,
  refreshReservations
  
}) => {
  return (
    <MyDialog
      open={openModal}
      onClose={onClose}
      title={isEdit ? getTranslation(
        "Edit Reservation",
        "Edit Reservation",
        "Edit Reservation"
      ) : getTranslation(
        "Add Reservation",
        "Add Reservation",
        "Add Reservation"
      )}
    >
      <div>
      <Alert variant="standard" color="warning" icon={<NotificationsActive/>}> {getTranslation("Adding reservation from admin side have no restrictions and no auto assigning of tables.","Adding reservation from admin side have no restrictions and no auto assigning of tables.","Adding reservation from admin side have no restrictions and no auto assigning of tables.")} </Alert>
       <ReservationForm
          isEdit={isEdit}
          selectedData={{...selectedReservation,oldPersons:selectedReservation.persons}}
          onCloseDialog={onClose}
          params={params}
          setParams={setParams}
          refreshReservations={() => refreshReservations()}

        />
      </div>
    </MyDialog>
  );
};

export default EditReservationModal;
