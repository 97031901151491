import { React, useEffect, useState } from "react";
import { Logo } from "../../../../components/Logo";
import { Get, GetFile } from "../../../../actions";
import { Get_Logo_URL } from "../../../../constants/apiUrls";

const Footer = ({ classes, enableLogo }) => {
	const [src, setSrc] = useState("");

	useEffect(() => {
		Get(
			{},
			Get_Logo_URL,
			null,
			(res) => {
				setSrc(GetFile(res.data));
			},
			(err) => {}
		);
	}, []);

	return (
		<div className={classes.footer}>
			<div className={classes.footerLogoWrapper}>
				{enableLogo ? (
					src ? (
						<img
							alt="SystemSettingLogo"
							src={src}
							style={{ width: "100%", height: "100%", margin: "5px" }}
						/>
					) : (
						<Logo className={classes.img} />
					)
				) : null}
			</div>
		</div>
	);
};

export default Footer;
