/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Menu, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { MyTable } from "../../../components/table";
import CutleryIcon from "../../../components/CurtleryIcon";
import PeopleIcon from "../../../components/PeopleIcon";
import {
	DeleteButton,
	EditButton,
} from "../../../components/ButttonsWithIcons";
import EditReservationModal from "./EditReservationModal";
import DeleteReservationModal from "./deleteReservationModal";
import { format } from "../../../heplers/format";
import {
	ChangeStatusIcon,
	CloseIcon,
	PrintIcon,
	ReportSingleIcon,
} from "../../../components/icons";
import {
	AddCircleOutline,
	DoneAll,
	PrintRounded,
	Smartphone,
	StayCurrentPortraitRounded,
} from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";
import { RiCloseCircleFill } from "react-icons/ri";
import CloseServiceModal from "./closeServiceModal";
import PrintDialog from "../../../components/PrintDialog";
import { getTranslation } from "../../../heplers/translationHelper";
import { MenuItem } from "@mui/material";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { size } from "lodash";
import { Post } from "../../../actions";
import { Post_ReservationStatus_URL } from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";

const columns = [
	{
		label: getTranslation("Title", "Title", "Title"),
		id: "title",
		component: ({ row }) => <Typography>{row.title}</Typography>,
	},

	{
		label: getTranslation("Time", "Time", "Time"),
		id: "reservationTime",
		// component: ({ row }) => <Typography>{row.reservationTime}</Typography>,
	},

	{
		label: "# " + getTranslation("Persons", "Persons", "Persons"),
		id: "persons",
		align: "right",
		component: ({ row }) => (
			<Typography align="right">{row.persons}</Typography>
		),
	},
	{
		label: "# " + getTranslation("Kids", "Kids", "Kids"),
		id: "kids",
		align: "right",
		component: ({ row }) => <Typography align="right">{row.kids}</Typography>,
	},
	// {
	//   label: getTranslation("Purpose","Purpose","Purpose"),
	//   id: "icons",
	//   component: ({ row }) => (
	//     <Box>
	//       {row.purposeOfReservation === 0 ? (
	//         <CutleryIcon width="20" height="20" />
	//       ) : (<>
	//         <PeopleIcon width="20" height="20" /> <span style={{fontSize:"30px"}}>/</span> <CutleryIcon width="20" height="20" /></>
	//       )}
	//     </Box>
	//   ),
	// },

	{
		label: getTranslation("Further info", "Further info", "Further info"),
		id: "info",
		component: ({ row }) => <Typography>{row.allergies || "N/A"}</Typography>,
	},
	{
		label: getTranslation(
			"Reserved Tables",
			"Reserved Tables",
			"Reserved Tables"
		),
		id: "tablesReserved",
		// component: ({ row }) => <Typography>{row.persons}</Typography>, // TODO: VERIFY THAT PERSONS VALUE SHOULD BE USED HERE
	},
	{
		label: getTranslation("Status", "Status", "Status"),
		id: "status",
		component: ({ row }) => (
			<Typography>
				{row.status == 0 ? "Reserved" : row.status == 1 ? "Completed" : "-"}
			</Typography>
		),
	},
	{
		label: getTranslation("Action", "Action", "Action"),
		id: "actions",
		align: "right",
	},
];

const ReservationTable = ({
	reservations,
	params,
	setParams,
	stats,
	loading,
	printData,
	refreshReservations,
}) => {
	const [selectedReservation, setSelectedReservation] = useState({
		persons: 0,
		reservationDate: Date.now(),
		purposeOfReservation: 0,
		timeSlotId: "",
		title: "",
		email: "",
		phone: "",
		subscribeForNews: false,
		haveSmallKids: false,
		kids: 0,
		acceptTerms: false,
		allergies: "",
	});
	const [isEdit, setIsEdit] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [showReservationForm, setShowReservationForm] = useState(false);
	const [showCloseService, setShowCloseService] = useState(false);
	const [print, setPrint] = useState(false);
	const componentRef = useRef();
	const { enqueueSnackbar } = useSnackbar();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => setPrint(false),
	});

	const handlePageChange = (e, page) => {
		setParams({ ...params, pageNumber: page });
	};

	const confirmDelete = (data) => {
		setShowDeleteConfirm(true);
		setSelectedReservation(data);
	};

	const handleEdit = (data) => {
		setIsEdit(true);
		setShowReservationForm(true);
		setSelectedReservation(data);
	};

	const handleSearch = (e) => {
		setParams({ ...params, searchString: e });
	};

	const handleRowsPerPageChange = (e) => {
		setParams({ ...params, pageSize: e.target.value });
	};

	useEffect(() => {
		if (print) {
			handlePrint();
		}
	}, [print]);

	const handleChangeStatus = (row) => {
		Post(
			[{ id: row?.id, status: 1 }],
			Post_ReservationStatus_URL,
			null,
			(res) => {
				enqueueSnackbar(
					res?.data
						? getTranslation(res?.data, res?.data, res?.data)
						: getTranslation(
								"Staus changed successfully",
								"Staus changed successfully",
								"Staus changed successfully"
						  ),
					{
						variant: "success",
					}
				);
				if (refreshReservations) refreshReservations();
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please try Again",
								"Something went wrong. Please try Again",
								"Something went wrong. Please try Again"
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};
	return (
		<>
			<MyTable
				loading={loading}
				title={getTranslation("Reservations", "Reservations", "Reservations")}
				viewSearch
				onSearch={handleSearch}
				colums={columns}
				rowsPerPage={params.pageSize}
				handleChangePage={handlePageChange}
				handleChangeRowsPerPage={handleRowsPerPageChange}
				page={params.pageNumber}
				rows={reservations.data}
				count={reservations.totalCount}
				viewEdit
				onEdit={(row) => handleEdit(row)}
				tableWidgets={
					<>
						<PrintRounded
							color="secondary"
							style={{ cursor: "pointer" }}
							onClick={handlePrint}
						/>
						<AddCircleOutline
							color="secondary"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setIsEdit(false);
								setShowReservationForm(true);
							}}
						/>
						{/* <RiCloseCircleFill
							style={{ cursor: "pointer" }}
							color="secondary"
							size={24}
							onClick={() => setShowCloseService(true)}
						/> */}
					</>
				}
				actions={[
					{
						component: (row) =>
							row.status == 0 ? (
								<IconButton
									onClick={() => {
										handleChangeStatus(row);
									}}
								>
									<DoneAll />
								</IconButton>
							) : null,
					},
					{
						component: (row) => (
							<DeleteButton
								onClick={() => {
									confirmDelete(row);
								}}
							/>
						),
					},
				]}
			/>
			<EditReservationModal
				isEdit={isEdit}
				openModal={showReservationForm}
				onClose={() => setShowReservationForm(false)}
				selectedReservation={selectedReservation}
				params={params}
				setParams={setParams}
				refreshReservations={() => refreshReservations()}
			/>
			<DeleteReservationModal
				openModal={showDeleteConfirm}
				onClose={setShowDeleteConfirm}
				selectedReservation={selectedReservation}
				params={params}
				refreshReservations={refreshReservations}
				setParams={setParams}
			/>
			<CloseServiceModal
				openDialog={showCloseService}
				handleClose={setShowCloseService}
			/>
			<div style={{ display: "none" }}>
				<PrintDialog ref={componentRef} params={params} rows={printData} />
			</div>
		</>
	);
};

export default ReservationTable;
