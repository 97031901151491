import {
	CardContent,
	Button,
	TextField,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import React, { memo, useState } from "react";
import CardComponent from "../../../components/Card";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DarkIcon, YellowIcon } from "../../../components/icons";
import DaySelectorModal from "./daySelectorModal";
import { daysConstant } from "../../../constants/general";
import { useSelector } from "react-redux";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { format } from "../../../heplers/format";
import Brightness6Icon from "@material-ui/icons/Brightness6";
import { StyledToggleButtonGroup } from "../../../components";
import { getTranslation } from "../../../heplers/translationHelper";
import { DaySelection } from "./daySelection";
import moment from "moment";
import { useEffect } from "react";
import { Get } from "../../../actions";
import { Get_ServerDateTime_URL } from "../../../constants/apiUrls";

const useStyles = makeStyles((theme) => ({
	grid: {
		justifyContent: "center",
		textAlign: "center",
		alignItems: "center",
	},
	btn: {
		background: theme.palette.primary.main,
		borderRadius: "5px",
		color: "#FFFFFF",
		height: "45px",

		"&:hover": {
			background: "white",
			color: theme.palette.primary.main,
			border: `1px solid ${theme.palette.primary.main}`,
		},
	},
	icons: {
		border: "none",

		"&.selected": {
			border: "2px",
		},
	},
	buttonCont: {
		"& :hover": {
			backgroundColor: "white",
		},
	},
}));

const Filtration = memo(({ params, setParams }) => {
	const [alignment, setAlignment] = React.useState(0);
	const [openModal, setOpenModal] = useState(false);

	const [selectedDate, setSelectedDate] = useState(format("date", new Date()));
	// const [serverTime, setServerTime] = useState();

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment != null) {
			setParams({ ...params, selectedDayTime: newAlignment });
			setAlignment(newAlignment);
		}
	};

	const classes = useStyles();

	return (
		<CardComponent>
			<CardContent>
				{/* <Typography>{serverTime}</Typography> */}
				<Grid container spacing={2} className={classes.grid}>
					<Grid item></Grid>
				</Grid>
				<DaySelection
					selectedDate={selectedDate}
					dayTime={alignment}
					setSelectedDate={(value) => {
						setParams({
							...params,
							selectedDate: moment(value, "DD-MM-YYYY").format("MM-DD-YYYY"),
							day: new Date(value).getDay(),
						});
						setSelectedDate(value);
					}}
				/>
			</CardContent>
			{/* <div style={{display:"flex",justifyContent:"center",paddingBottom:"10px" }}>
       <StyledToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton
                value={0}
                aria-label="left aligned"
              >
                <WbSunnyIcon />
              </ToggleButton>

              <ToggleButton value={1} aria-label="right aligned">
                <Brightness6Icon />
              </ToggleButton>
            </StyledToggleButtonGroup>
      </div> */}
		</CardComponent>
	);
});

export default Filtration;
