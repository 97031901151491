import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Get } from "../../../../actions";
import { Get_All_TimeSlots_URL } from "../../../../constants/apiUrls";
import { DayTimeSelection } from "../maxCapacity/dayTimeSelection";
import DaysTabs from "./daysTabs";
import TimeSlotTable from "./timeSlotTable";

const TimeSlotTab = () => {
  const [queryParams, setQueryParams] = useState({
    selectedDate: new Date(),
    dayTime: 0,
    day: new Date().getDay(),
    pageNumber: 0,
    pageSize: 10,
  });
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);

  const getTimeSlots = (payload) => {
    setLoadingTimeSlots(true);
    Get(payload, Get_All_TimeSlots_URL, null, (res) => {
      setLoadingTimeSlots(false);
      setTimeSlots(res.data);
    });
  };

  useEffect(
    () => getTimeSlots({ day: queryParams?.day }),
    [queryParams.day]
  );

  let filterByDayTime =
    queryParams.dayTime === null
      ? timeSlots
      : timeSlots.filter((slot) => slot.dayTime === queryParams.dayTime);

  return (
    <div>
      <DayTimeSelection params={queryParams} setParams={setQueryParams} />
      <DaysTabs params={queryParams} setParams={setQueryParams} />
      <TimeSlotTable
        params={queryParams}
        setParams={setQueryParams}
        timeSlots={filterByDayTime}
        loadingTimeSlots={loadingTimeSlots}
        refreshTimeSlots={() => getTimeSlots({ day: queryParams?.day })}
      />
    </div>
  );
};

export default TimeSlotTab;
