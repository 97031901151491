import { React, useState, useEffect } from "react";
import CardComponent from "../../../../components/Card";
import { CardContent } from "@material-ui/core";
import { UploadFiles } from "../../../../components/Upload";
import { getTranslation } from "../../../../heplers/translationHelper";
import {
	Get_Logo_URL,
	Post_DeleteLogo_URL,
	Post_ChangeLogo_URL,
} from "../../../../constants/apiUrls";
import { Post, Get } from "../../../../actions";
import { useSnackbar } from "notistack";

const Logo = () => {
	const [selectedFile, setSelectedFile] = useState();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getLogo();
	}, []);

	const getLogo = () => {
		Get(
			{},
			Get_Logo_URL,
			null,
			(res) => {
				if (res?.data?.logoId) setSelectedFile([res?.data]);
			},
			(err) => {
				// enqueueSnackbar(
				// 	err?.data
				// 		? getTranslation(err?.data, err?.data, err?.data)
				// 		: getTranslation(
				// 				"Something went wrong. Please contact with admin.",
				// 				"Something went wrong. Please contact with admin.",
				// 				"Something went wrong. Please contact with admin."
				// 		  ),
				// 	{
				// 		variant: "error",
				// 	}
				// );
			}
		);
	};

	const handleDeleteLogo = (file) => {
		Post(
			{ logoId: file },
			Post_DeleteLogo_URL,
			null,
			(res) => {
				enqueueSnackbar(
					getTranslation("Logo Deleted.", "Logo Deleted.", "Logo Deleted."),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangeLogo = (file) => {
		Post(
			{ File: file },
			Post_ChangeLogo_URL,
			null,
			(res) => {
				enqueueSnackbar(
					getTranslation(
						"Logo updated successfully.",
						"Logo updated successfully.",
						"Logo updated successfully."
					),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<>
			<CardComponent style={{ marginTop: 20 }}>
				<CardContent>
					<label>{getTranslation("Logo", "Logo", "Logo")}</label>
					<br />
					<br />
					<UploadFiles
						multiple={false}
						onClick={(address) => {}}
						uploadedFiles={selectedFile ? selectedFile : []}
						getFileName={(file) => file}
						onAddFile={(file) => {
							handleChangeLogo(file);
						}}
						onDeleteFile={(file) => {
							handleDeleteLogo(file);
						}}
					/>
				</CardContent>
			</CardComponent>
		</>
	);
};

export default Logo;
