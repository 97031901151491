import React, { useState } from "react";
import styles from "./styles.module.css";
import DaysOffTable from "./daysOffTable";
import DaysRangeCard from "./daysRangeCard";
import { Get } from "../../../../actions";
import { Get_All_DaysOff_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import { useEffect } from "react";

const DaysOffTab = () => {
  const [loadingDaysOff, setLoadingDaysOff] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [daysOff, setDaysOff] = useState([]);
  const defaultYear = new Date().getFullYear();
  const [queryParams, setQueryParams] = useState({
    from: new Date(`${defaultYear}-01-01T06:14:00.000Z`),
    to: new Date(`${defaultYear}-12-31T06:14:00.000Z`),
    pageNumber: 0,
    pageSize: 10,
  });

  const getDaysOff = (payload) => {
    setLoadingDaysOff(true);

    Get(
      payload,
      Get_All_DaysOff_URL,
      null,
      (res) => {
        setLoadingDaysOff(false);
        setDaysOff(res.data);
      },
      (err) => {
        setLoadingDaysOff(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              )
        );
      }
    );
  };

  useEffect(() => {
    getDaysOff({ from: queryParams.from, to: queryParams.to });
  }, [queryParams]);

  return (
    <div className={styles.tabWrapper}>
      <DaysRangeCard params={queryParams} setParams={setQueryParams} />
      <DaysOffTable
        isLoading={loadingDaysOff}
        daysOff={daysOff}
        params={queryParams}
        setParams={setQueryParams}
      />
    </div>
  );
};

export default DaysOffTab;
