import { React, useState, useEffect } from "react";
import CardComponent from "../../../../components/Card";
import { CardContent, Tooltip } from "@material-ui/core";
import { UploadFiles } from "../../../../components/Upload";
import { getTranslation } from "../../../../heplers/translationHelper";
import {
	Get_AllMenuFile_URL,
	Post_AddMenuFile_URL,
} from "../../../../constants/apiUrls";
import { Post, Get, DownloadFile } from "../../../../actions";
import { useSnackbar } from "notistack";
import { DownloadButton } from "../../../../components/ButttonsWithIcons";

const Menu = () => {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getMenu();
	}, []);

	const getMenu = () => {
		Get(
			{},
			Get_AllMenuFile_URL,
			null,
			(res) => {
				if (res?.data) {
					setSelectedFiles([res?.data]);
				}
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangeMenu = (file) => {
		Post(
			{ File: file },
			Post_AddMenuFile_URL,
			null,
			(res) => {
				enqueueSnackbar(
					getTranslation(
						"Menu changed successfully.",
						"Menu changed successfully.",
						"Menu changed successfully."
					),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<>
			<CardComponent style={{ marginTop: 20 }}>
				<CardContent>
					<label>{getTranslation("Menu", "Menu", "Menu")}</label>
					<br />
					<br />
					<UploadFiles
						multiple={true}
						uploadedFiles={selectedFiles.length > 1 ? selectedFiles : []}
						onClick={(address) => {}}
						getFileName={(file) => file}
						onAddFile={(address) => {
							handleChangeMenu(address);
							// setSelectedFiles([
							// 	...selectedFiles,
							// 	{
							// 		fileAddress: address,
							// 	},
							// ]);
						}}
						onDeleteFile={(file) => file}
					/>
				</CardContent>
			</CardComponent>
		</>
	);
};

export default Menu;
