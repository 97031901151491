import {
  DELETING_USER_RESERVATION,
  GET_TIME_SLOT,
  GET_USER_RESERVATION,
  LOADING_USER_RESERVATION,
  SET_DAY,
  SET_DAYTIME,
  SET_ERRORS,
  SET_LASTUPDATEOFRESERVATIONS,
  SET_PAGE_NUMBER,
  SET_RESERVATION_STATS,
  SET_SELECTED_DATE,
  SET_SELECTED_RESERVATION,
} from "../constants/types";

const initialState = {
  pageNumber: 0,
  stats: {
    maxCapacity: 0,
    numberOfGroups: 0,
    numberOfPersons: 0,
    percentage: "0%",
  },
  selectedReservation: {
    persons: 0,
    reservationDate: Date.now(),
    purposeOfReservation: 0,
    timeSlotId: "",
    fullName: "",
    email: "",
    phone: "",
    subscribeForNews: false,
    haveSmallKids: false,
    kids: 0,
    acceptTerms: false,
    allergies: "",
  },
  timeSlotData: [],
  userReservations: [],
  isDeletingReservations: false,
  isLoadingReservations: false,
  selectedDate: new Date(),
  dayTime: 0,
  day: 0,
  reservationError: {},
  lastUpdateTime:new Date(),
  isAdmin:"",
};

const userReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_RESERVATION:
      return {
        ...state,
        userReservations: action.payload,
      };
    case GET_TIME_SLOT:
      return {
        ...state,
        timeSlotData: action.payload,
      };
    case LOADING_USER_RESERVATION:
      return {
        ...state,
        isLoadingReservations: action.payload,
      };
    case DELETING_USER_RESERVATION:
      return {
        ...state,
        isDeletingReservations: action.payload,
      };
    case SET_ERRORS: 
      return {
        ...state,
        reservationError: action.payload,
      }
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case SET_DAYTIME:
      return {
        ...state,
        dayTime: action.payload,
      };
    case SET_DAY:
      return {
        ...state,
        day: action.payload,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_RESERVATION_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case SET_SELECTED_RESERVATION:
      return {
        ...state,
        selectedReservation: action.payload,
      };
      case SET_LASTUPDATEOFRESERVATIONS:
        return {
          ...state,
          lastUpdateTime: action.payload,
        };
        case "SETADMIN":{
          return {
            ...state,
            isAdmin:action.payload
          }
        }
    default:
      return state;
  }
};

export default userReservationReducer;
