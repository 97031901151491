import Moment from 'moment';

export function format(formatType, value) {
    if (typeof value !== 'undefined') {
      switch (formatType) {
        case "date":
          return Moment(value).format('DD-MM-YYYY');
  
        case "dateTime":
          return (value).format('DD-MM-YYYY HH:mm');
        case "time":
          return Moment(value).format('HH:mm');
        case "number":
          return value ? value.toFixed(2) : "0.00";
        default:
          return value;
      }
    }
    else
      return '-'
  }