/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Get } from "../../../../actions";
import { Get_MaxCapacity_URL } from "../../../../constants/apiUrls";

import { useDaysOffStyles } from "../daysOffTable/daysOffStyles";
import { DayTimeSelection } from "./dayTimeSelection";
import { MaxCapacityTable } from "./maxCapacityTable";
import { getTranslation } from "../../../../heplers/translationHelper";

const MaxCapacity = () => {
	const classes = useDaysOffStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [queryParams, setQueryParams] = useState({
		dayTime: 0,
		pageNumber: 0,
		pageSize: 10,
	});
	const [isLoadingMaxCapacity, setIsLoadingMaxCapacity] = useState(false);
	const [maxCapacities, setMaxCapacities] = useState([]);

	const getMaxCapacities = (payload) => {
		setIsLoadingMaxCapacity(true);
		Get(
			payload,
			Get_MaxCapacity_URL,
			null,
			(res) => {
				setIsLoadingMaxCapacity(false);
				setMaxCapacities(res.data);
			},
			(err) => {
				setIsLoadingMaxCapacity(false);
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	useEffect(() => {
		getMaxCapacities({
			dayTime: queryParams.dayTime,
		});
	}, [queryParams]);

	return (
		<>
			<DayTimeSelection params={queryParams} setParams={setQueryParams} />
			{/* <div className={classes.daysRangeCard} style={{ marginTop: "1rem" }}>
        <Typography variant={"h3"} color="secondary">
          Table Summary
        </Typography>
      </div> */}
			<br />
			<MaxCapacityTable
				queryParams={queryParams}
				setQueryParams={setQueryParams}
				maxCapacities={maxCapacities}
				loadingMaxCapacity={isLoadingMaxCapacity}
			/>
		</>
	);
};

export default MaxCapacity;
