import React from "react";
import { Card, Divider, CardHeader, makeStyles } from "@material-ui/core";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		color: theme.palette.primary.contrastText,
		// background: theme.palette.primary.primary,
		background: "#004467",
	},
}));

const CardComponent = React.forwardRef(
	({ title = "", children, ...rest }, ref) => {
		const classes = useStyles();
		return (
			<Card ref={ref} {...rest}>
				{title ? (
					<CardHeader className={classes.cardHeader} title={title} />
				) : null}
				{/* <Divider /> */}
				{children}
			</Card>
		);
	}
);

export default CardComponent;
