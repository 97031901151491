import {
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { DatePicker } from "../../../components/DatePicker";
import React, { useEffect, useState } from "react";
import userReservationStyles from "../../../assests/styles/views/public/userReservation";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";
import TimeSlots from "../component/ReservationForm/timeSlot";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Get_All_DaysOff_URL,
  Get_CalendarDaysOff_URL,
  Get_Reservation_URL,
  Get_Reservations_URL,
  Update_Reservation_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router-dom";
import { PickersDay } from "@mui/x-date-pickers";
import { Get, Post } from "../../../actions";
import { useTableManagementStyles } from "../../settings/tableManagementStyles";

const useStyles = makeStyles((theme) => ({
  typo: {
    backgroundColor: theme.palette.primary.orange,
    color: theme.palette.common.white,
  },
}));
const EditReservation = ({
  isEdit,
  selectedData,
  // classes,
  defaultPrimaryColor,
}) => {
  const styleClasses = useStyles();
  const classes = userReservationStyles();
  const params = useParams();
  const { reservationId, primaryColor, secondaryColor } = params;
  const [loadingDaysOff, setLoadingDaysOff] = useState(false);
  const [loading, setLoading] = useState(false);
  const [daysOff, setDaysOff] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedReservation, setSelectedReservaton] = useState({
    persons: 1,
    reservationDate: new Date(),
    purposeOfReservation: 0,
    timeSlotId: "",
    fullName: "",
    title: "",
    email: "",
    phone: "",
    subscribeForNews: false,
    haveSmallKids: false,
    kids: 0,
    acceptTerms: false,
    allergies: "",
    startTime: "",
  });

  const validationSchema = Yup.object().shape({
    reservationDate: Yup.date().test(
      "reservation_Date",
      getTranslation("Selected date is off day."),
      function (val) {
        return !isOffDay(val);
      }
    ),
    persons: Yup.number().min(
      1,
      getTranslation("Please select at least one person")
    ),
    purposeOfReservation: Yup.number(),
  });

  const CssRadio = withStyles({
    colorSecondary: {
      color: "gray",
      "&$checked": {
        color: "#" + primaryColor,
      },
    },
    checked: {},
  })(Radio);

  const getDaysOff = (payload) => {
    setLoadingDaysOff(true);

    Get(
      payload,
      Get_CalendarDaysOff_URL,
      null,
      (res) => {
        setDaysOff(res.data);
      },
      (err) => {
        setLoadingDaysOff(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    );
    setLoading(true);
    Get(
      {
        id: reservationId,
      },
      Get_Reservation_URL,
      null,
      (res) => {
        setSelectedReservaton(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation("Something went wrong. Please try again.")
        );
      }
    );
  };
  useEffect(() => {
    const currentDate = new Date();
    while (isOffDay(currentDate)) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setSelectedReservaton({
      ...selectedReservation,
      reservationDate: new Date(currentDate),
    });
    setLoadingDaysOff(false);
  }, [daysOff]);

  useEffect(() => {
    const date = new Date();
    const to = new Date();
    to.setMonth(date.getMonth() + 2, 1);
    getDaysOff({ from: date, to: to });
  }, []);

  const handleSubmit = (payload, actions) => {
    const id = reservationId;
    Post(
      payload,
      Update_Reservation_URL,
      null,
      (res) => {
        actions.setSubmitting(false);

        enqueueSnackbar(
          getTranslation(
            "Reservation created successfully",
            "Reservation created successfully",
            "Reservation created successfully"
          ),
          {
            variant: "success",
          }
        );
        // setActiveStep(0);
        // onCloseDialog(false);
        // if(refreshReservations)
        //   refreshReservations();
      },
      (err) => {
        if (!err) return;
        actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Something went wrong. Please try Again",
            "Something went wrong. Please try Again",
            "Something went wrong. Please try Again"
          ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const daysOffByday = daysOff.filter((x) => x.dayOffType == 0);
  const daysOffByperiod = daysOff.filter((x) => x.dayOffType == 1);

  const isOffDay = (date) => {
    // Check if it is non period date
    for (let index = 0; index < daysOffByday.length; index++) {
      const r = moment(daysOffByday[index].startDate).isSame(date, "date");
      if (r) return r;
      // return false
    }

    for (let index = 0; index < daysOffByperiod.length; index++) {
      const r =
        moment(date).isAfter(daysOffByperiod[index].startDate, "date") &&
        moment(date).isBefore(daysOffByperiod[index].endDate, "date");
      if (r) return r;
      // return false
    }
  };



  return (
    <div>
      {loadingDaysOff || loading ? (
        <CircularProgress />
      ) : (
        <div className={classes.inputsWrapper}>
          <Formik          
            // enableReinitialize={loadingDaysOff}
            validationSchema={validationSchema}
            initialValues={selectedReservation}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              if (!values.timeSlotId) {
                actions.setSubmitting(false);
                enqueueSnackbar(
                  getTranslation(
                    " Please Select a time slot",
                    " Please Select a time slot ",
                    " Please Select a time slot "
                  ),
                  {
                    variant: "error",
                  }
                );
                return;
              }
              if (values.haveSmallKids && values.kids < 1) {
                actions.setSubmitting(false);
                enqueueSnackbar(
                  getTranslation(
                    "Minimum 1 kid required",
                    "Minimum 1 kid required",
                    "Minimum 1 kid required"
                  ),
                  {
                    variant: "error",
                  }
                );
                return;
              }

              const data = {
                ...values,
                reservationDate: new Date(values.reservationDate).toISOString(),
              };
              handleSubmit(data, actions);
            }}
            validateOnChange={false}
          >
            {({
              values,
              setValues,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <>
                    <Typography
                      variant="h2"
                      mt="20px"
                      backgroundColor="orange"
                      className={classes.typo}
                    >
                      Edit-Reservation
                    </Typography>
                    <div
                      className={classes.inputConts}
                      style={{ marginTop: "50px" }}
                    >
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label={getTranslation(
                          "Number of Persons",
                          "Number of Persons",
                          "Number of Persons"
                        )}
                        name="persons"
                        type="number"
                        size="medium"
                        value={values.persons}
                        onChange={handleChange}
                        error={touched.persons && Boolean(errors.persons)}
                        helperText={touched.persons && errors.persons}
                      />
                      <DatePicker
                        className={classes.datePicker}
                        required
                        size="medium"
                        label={getTranslation("Date", "Date", "Date")}
                        value={values.reservationDate}
                        onChange={(val) => {
                          setFieldValue("reservationDate", val);
                          setFieldValue("timeSlotId", "");
                        }}
                        id="date"
                        name="reservationDate"
                        renderDay={(
                          day,
                          selectedDate,
                          isInCurrentMonth,
                          dayComponent
                        ) => {
                          // You can also use our internal <Day /> component
                          return (
                            <div
                              style={
                                isOffDay(day)
                                  ? { textDecoration: "line-through" }
                                  : null
                              }
                            >
                              {dayComponent}
                            </div>
                          );
                        }}
                        disablePast
                        shouldDisableDate={(day) => isOffDay(day)}
                        error={
                          touched.reservationDate &&
                          Boolean(errors.reservationDate)
                        }
                        helperText={
                          touched.reservationDate && errors.reservationDate
                        }
                      />
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label={getTranslation(
                          "Number of Kids",
                          "Number of Kids",
                          "Number of Kids"
                        )}
                        name="kids"
                        type="number"
                        size="small"
                        value={values.kids}
                        onChange={handleChange}
                        error={touched.kids && Boolean(errors.kids)}
                        helperText={touched.kids && errors.kids}
                      />
                    </div>

                    {/* {isEdit && (
                    <div>
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        multiline
                        minRows={5}
                        fullWidth
                        label={getTranslation(
                          "Give information of allergies and Intolerance",
                          "Give information of allergies and Intolerance",
                          "Give information of allergies and Intolerance"
                        )}
                        name="allergies"
                        type="number"
                        size="small"
                        value={values.allergies}
                        onChange={handleChange}
                        error={touched.allergies && Boolean(errors.allergies)}
                        helperText={touched.allergies && errors.allergies}
                      />
                      <FormControlLabel
                        label={"Select if there will be kids < 4 years old"}
                        name="haveSmallKids"
                        value={values.haveSmallKids}
                        onChange={(val) => {
                          const { checked } = val.target;
                          setFieldValue("haveSmallKids", checked);
                        }}
                        control={
                          <Checkbox
                            checked={values.haveSmallKids}
                            // className={classes.checkBox}
                            style={{ color: defaultPrimaryColor }}

                          />
                        }
                      />
                    </div>
                  )} */}

                    {values.haveSmallKids && (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label={getTranslation(
                          "Number of Kids",
                          "Number of Kids",
                          "Number of Kids"
                        )}
                        name="kids"
                        type="number"
                        size="small"
                        value={values.kids}
                        onChange={handleChange}
                        error={touched.kids && Boolean(errors.kids)}
                        helperText={touched.kids && errors.kids}
                      />
                    )}
                    <div style={{ marginTop: "1rem" }}>
                      <FormLabel id="selectTimeSlot">
                        {getTranslation(
                          "Select Time Slot / Arriving time",
                          "Select Time Slot / Arriving time",
                          "Select Time Slot / Arriving time"
                        )}
                      </FormLabel>
                      <TimeSlots
                        isEdit={isEdit}
                        selectedReservation={selectedData}
                        setSelectedReservation={(value) =>
                          setSelectedReservaton(value)
                        }
                        formData={values}
                        setTimeSlotId={setValues}
                        classes={classes}
                      />
                    </div>

                    <ButtonWithLoading
                      type="submit"
                      loading={isSubmitting}
                      title={getTranslation("Submit", "Submit", "Submit")}
                      variant="contained"
                      style={
                        isSubmitting == false
                          ? {
                              backgroundColor: "#" + primaryColor,
                              color: "#" + secondaryColor,
                            }
                          : {
                              backgroundColor: "gray",
                              color: "#" + primaryColor,
                            }
                      }
                    />
                  </>
                </form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EditReservation;
