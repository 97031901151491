import { CircularProgress, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "../../../actions";
import {
  getCustomers,
  searchCustomers,
} from "../../../actions/customerMangementActions";
import { MyTable } from "../../../components";
import {
  DeleteButton,
  EditButton,
} from "../../../components/ButttonsWithIcons";
import CardComponent from "../../../components/Card";
import AlertDialog from "../../../components/DeleteDialog";
import { Delete_Customers_URL } from "../../../constants/apiUrls";
import { SET_CUSTOMER_PAGE_NUMBER } from "../../../constants/types";
import { getTranslation } from "../../../heplers/translationHelper";
import { useCustomerManagementStyles } from "../customerManagementStyles";

const columns = [
  {
    label: getTranslation("Full Name", "Full Name", "Full Name"),
    id: "fullName",
    component: ({ row }) => <Typography>{row.fullName}</Typography>,
  },
  {
    label: getTranslation("Phone Number", "Phone Number", "Phone Number"),
    id: "phoneNumber",
    component: ({ row }) => <Typography>{row.phone}</Typography>,
  },
  {
    label: getTranslation("Email", "Email", "Email"),
    id: "email",
    component: ({ row }) => <Typography>{row.email}</Typography>,
  },
];

const CustomersTable = ({
  queryParams,
  setQueryParams,
  isLoadingCustomers,
  setIsEdit,
  formData,
  setFormData,
  stat,
  customers,
}) => {
  const classes = useCustomerManagementStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlePageChange = (e, page) => {
    setQueryParams({ ...queryParams, pageNumber: page });
  };

  const handleSearch = (e) => {
    setQueryParams({ ...queryParams, searchString: e });
  };

  const handleRowsPerPageChange = (e) => {
    setQueryParams({ ...queryParams, pageSize: e.target.value });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    Get(
      formData,
      Delete_Customers_URL,
      null,
      (res) => {
        setIsDeleting(false);
        enqueueSnackbar(
          getTranslation(
            "Deleted Successfully",
            "Deleted Successfully",
            "Deleted Successfully"
          ),
          {
            variant: "success",
          }
        );
        setOpenDeleteModal(false);
        setQueryParams({ ...queryParams, pageNumber: 0 });
      },
      (err) => {
        setIsDeleting(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please Try Again.",
                "Something went wrong. Please Try Again.",
                "Something went wrong. Please Try Again."
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  return (
    <div className={classes.tableWrapper}>
      <MyTable
        title={getTranslation("Customers","Customers","Customers")}
        loading={isLoadingCustomers}
        viewSearch
        onSearch={handleSearch}
        colums={columns}
        rowsPerPage={queryParams.pageSize}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleRowsPerPageChange}
        page={queryParams.pageNumber}
        rows={customers}
        count={stat.totalCount}
        viewEdit
        onEdit={(row) => {
          setIsEdit(true);
          setFormData(row);
        }}
      />
      <AlertDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deleting={isDeleting}
        onSubmit={handleDelete}
      />
    </div>
  );
};

export default CustomersTable;
