import React, { useState } from "react";
import {
	AppBar,
	Box,
	Typography,
	Button,
	Toolbar,
	Avatar,
	Menu,
	IconButton,
	MenuItem,
	Tooltip,
	Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { HiOutlineLogout, HiUserCircle } from "react-icons/hi";
import useStyles from "../../assests/styles/layout/topbar";
import SubMenus from "./submenu";
import { Logout } from "../../actions";
import authUtils from "../../utils/authUtils";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {
	CodeOutlined,
	ContactSupportOutlined,
	FileCopy,
	HelpOutline,
} from "@material-ui/icons";
import {
	DutchLanguageIcon,
	EnglishLanguageIcon,
	FrenchLanguageIcon,
	GermanLanguageIcon,
} from "../../components/icons";
import { getTranslation } from "../../heplers/translationHelper";
import ReservationNavTabs from "../../views/Reservations/components/navTabs";
import authService from "../../utils/authUtils";
import { useSnackbar } from "notistack";
import { CustomIframeDialog } from "./customIframeDialog";
import CloseServiceModal from "../../views/Reservations/components/closeServiceModal";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import theme from "../../theme/index";

function TopBar({ className, navOpen, handleDrawerOpen, ...rest }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const pathname = window.location.pathname;
	const locationOrigin = window.location.origin;
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const customerId = authService.getCustomerId();

	var [profileMenu, setProfileMenu] = useState(null);

	const { user } = useSelector((state) => state.auth);
	const handleNavigateToProfile = () => {
		history.push("/account/profile");
		setProfileMenu(null);
	};

	const handleLogout = () => {
		dispatch(Logout()).then((resp) => {
			authUtils.logout();
			history.push("/login");
		});
	};

	const copyTextToClipboard = async (text) => {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand("copy", true, text);
		}
	};

	const [openCustomIframe, setOpenCustomIframe] = useState(false);
	const [showCloseService, setShowCloseService] = useState(false);
	const handleCopyGeneratedLink = () => {
		setOpenCustomIframe(true);
		// const generatedLink = `<iframe src="${locationOrigin}/public/user-reservation/${customerId}" width="500px" height="600px"></iframe>`;
		// copyTextToClipboard(generatedLink)
		//   .then(() =>
		//     enqueueSnackbar(getTranslation("Copied!", "Copied!", "Copied!"), {
		//       variant: "success",
		//     })
		//   )
		//   .catch((err) => {
		//    enqueueSnackbar(getTranslation("Failed!", "Failed!", "Failed!"));
		//   });
	};

	const handleContactUs = () => {
		window.open("https://form.jotform.com/220413974982361", "_blank");
	};
	return (
		<AppBar
			className={clsx(classes.root, {
				[classes.rootshift]: !navOpen,
			})}
			{...rest}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={clsx(classes.menuButton, navOpen && classes.hide)}
				>
					<MenuIcon />
				</IconButton>
				{/* {pathname === "/reservations" ||
      			    pathname === "/reservations/current" ||
      			    pathname === "/reservations/history" ? (
      			    <ReservationNavTabs />
      			  ) : null} */}
				<SubMenus />
				<Box
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
				>
					<div style={{ marginRight: "35px" }}>
						<ButtonWithLoading
							onClick={() => setShowCloseService(true)}
							title={getTranslation(
								"Close Service",
								"Close Service",
								"Close Sevice"
							)}
							textColor="white"
							type="button"
							variant="contained"
							color="primary"
							size="small"
						/>
					</div>
					{/* <IconButton
            className={classes.helpButton}
            style={{ marginRight: window.innerWidth < 1000 ? "0px" : "20px" }}
            onClick={handleCopyGeneratedLink}
          >
            <Tooltip
              title={getTranslation(
                "Copy personalized address",
                "Copy personalized address",
                "Copy personalized address"
              )}
              arrow
            >
              <CodeOutlined fontSize="medium" />
            </Tooltip>
        </IconButton> */}
					<Select
						style={{
							marginRight: window.innerWidth < 1000 ? "0px" : "20px",
							width: window.innerWidth < 1000 ? "100px" : "160px",
							height: "34px",
							borderRadius: "17px",
						}}
						variant="outlined"
						defaultValue={
							localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
						}
						onChange={(e) => {
							localStorage.setItem("lang", e.target.value);
							window.location.reload(true);
						}}
					>
						<MenuItem value="en">
							<IconButton
								style={{
									marginRight: window.innerWidth < 1000 ? "0px" : "10px",
								}}
							>
								<EnglishLanguageIcon />
							</IconButton>
							{window.innerWidth > 1000
								? getTranslation("English", "English", "English")
								: null}
						</MenuItem>
						<MenuItem value="fr">
							<IconButton
								style={{
									marginRight: window.innerWidth < 1000 ? "0px" : "10px",
								}}
							>
								<FrenchLanguageIcon />
							</IconButton>
							{window.innerWidth > 1000
								? getTranslation("French", "French", "French")
								: null}
						</MenuItem>
						<MenuItem value="gr">
							<IconButton
								style={{
									marginRight: window.innerWidth < 1000 ? "0px" : "10px",
								}}
							>
								<GermanLanguageIcon />
							</IconButton>
							{window.innerWidth > 1000
								? getTranslation("German", "German", "German")
								: null}
						</MenuItem>
						<MenuItem value="dt">
							<IconButton
								style={{
									marginRight: window.innerWidth < 1000 ? "0px" : "10px",
								}}
							>
								<DutchLanguageIcon />
							</IconButton>
							{window.innerWidth > 1000
								? getTranslation("Dutch", "Dutch", "Dutch")
								: null}
						</MenuItem>
					</Select>
					<IconButton className={classes.helpButton} onClick={handleContactUs}>
						<Tooltip
							title={getTranslation(
								"Contact Us",
								"Contactez-nous",
								"Kontaktieren Sie uns"
							)}
							arrow
						>
							<HelpOutline fontSize="large" />
						</Tooltip>
					</IconButton>

					<IconButton
						// style={{ backgroundColor: "rgb(231, 66, 76)" }}
						style={{ backgroundColor: theme.palette.primary.main }}
						aria-haspopup="true"
						aria-controls="profile-menu"
						variant="circular"
						className={classes.avatar}
						onClick={(e) => {
							setProfileMenu(e.currentTarget);
						}}
					>
						{user?.firstName?.slice(0, 1)}
						{user?.lastName?.slice(0, 1)}
					</IconButton>

					<Menu
						id="profile-menu"
						open={Boolean(profileMenu)}
						anchorEl={profileMenu}
						onClose={() => setProfileMenu(null)}
						disableAutoFocusItem
						className={classes.menuBar}
						MenuListProps={{ className: classes.profileMenuList }}
					>
						<MenuItem>
							<Box
								onClick={handleNavigateToProfile}
								display="flex"
								alignItems="center"
							>
								<HiUserCircle className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("My Account", "Mon compte", "Mein Konto")}
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem>
							<Box display="flex" alignItems="center" onClick={handleLogout}>
								<HiOutlineLogout className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("Logout", "Se d�connecter", "Ausloggen")}
								</Typography>
							</Box>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
			{/* <CustomIframeDialog
				open={openCustomIframe}
				onClose={() => setOpenCustomIframe(false)}
			/> */}
			<CloseServiceModal
				openDialog={showCloseService}
				handleClose={setShowCloseService}
			/>
		</AppBar>
	);
}

export default withRouter(TopBar);
