import { makeStyles } from "@material-ui/core";

export const useDaysOffStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "100%",
    height: "70vh",
  },
  daysRangeCard: {
    width: "100%",
    height: "5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
    marginBottom: "1rem",
    "& > div": {
      marginRight: "3rem",
    },
    "@media screen and (max-width: 1000px)": {
      height: "8rem",
    },
  },
  fromTo: {
    color: "white",
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  fromToValue: {
    width: "max-content",
    padding: "0.5rem 0",
    color: "white",
    fontSize: "1.5rem",
    fontWeight: 400,
    cursor: "pointer",
    borderBottom: "1px solid white",
  },
}));
