import { Box, IconButton, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../../../components/icons";
import { Logo } from "../../../../components/Logo";
import LanguageSelector from "../languageSelector";

const Navbar = ({classes}) => {
  // const {primaryColor,secondaryColor} = useParams();
  // const classes = userReservationStyles({ primaryColor : "green",secondaryColor : "rgba(255, 35, 35, 0.83)"});
  return (
    <nav className={classes.navbar}>
      {/* <div className={classes.logoWrapper}>
        <Logo className={classes.img} />
      </div> */}
      <LanguageSelector isPublic />
    </nav>
  );
};

export default Navbar;
