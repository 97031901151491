import { Box, Card, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { Get } from "../../../../actions";
import { MyTable } from "../../../../components";
import {
	DeleteButton,
	EditButton,
} from "../../../../components/ButttonsWithIcons";
import CardComponent from "../../../../components/Card";
import AlertDialog from "../../../../components/DeleteDialog";
import { Delete_MaxCapacity_URL } from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { useTableManagementStyles } from "../../tableManagementStyles";
import { AddTable } from "./addTable";
import _ from "lodash";
import theme from "../../../../theme/index";

const columns = [
	{
		id: "persons",
		label: getTranslation("Table Type", "Table Type", "Table Type"),
		component: ({ row }) => <Typography>{row.persons} Persons</Typography>,
	},

	{
		id: "quantity",
		label: getTranslation("Quantity", "Quantity", "Quantity"),
		component: ({ row }) => <Typography>{row.quantity}</Typography>,
	},
	{
		label: getTranslation("Action", "Action", "Action"),
		id: "actions",
		align: "right",
	},
];

export const MaxCapacityTable = ({
	queryParams,
	setQueryParams,
	maxCapacities,
	loadingMaxCapacity,
}) => {
	const classes = useTableManagementStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [showCreateTableDialog, setShowCreateTableDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [tableData, setTableData] = useState({
		id: null,
		persons: 0,
		quantity: 0,
		dayTime: 0,
	});

	//handlers
	const handleChangePage = (e, newPage) => {
		setQueryParams({ ...queryParams, pageNumber: newPage });
	};

	const handleChangeRowsPerPage = (event) => {
		setQueryParams({ ...queryParams, pageSize: event });
	};

	const handleDelete = () => {
		setIsDeleting(true);
		Get(
			tableData,
			Delete_MaxCapacity_URL,
			null,
			(res) => {
				setIsDeleting(false);
				enqueueSnackbar(
					getTranslation(
						"Deleted Successfully",
						"Deleted Successfully",
						"Deleted Successfully"
					),
					{
						variant: "success",
					}
				);
				setOpenDeleteModal(false);
				setQueryParams({ ...queryParams, dayTime: queryParams.dayTime });
			},
			(err) => {
				setIsDeleting(false);
				enqueueSnackbar(err.data, {
					variant: "error",
				});
			}
		);
	};

	const totalCapacity = _.sum(
		maxCapacities.map((capacity) => capacity.persons * capacity.quantity)
	);

	return (
		<Card>
			<Box padding="1rem" bgcolor={theme.palette.primary.main} display="flex">
				<Grid container spacing={2}>
					<Grid item lg={4} sm={6}>
						<Typography
							style={{ color: theme.palette.primary.contrastText }}
							variant="h5"
						>
							{getTranslation(
								"Maximum Capacity",
								"Maximum Capacity",
								"Maximum Capacity"
							)}
						</Typography>
					</Grid>
					<Grid item lg={4} sm={6}>
						{/* <Typography color="white" variant="h5"> */}
						<Typography
							style={{ color: theme.palette.primary.contrastText }}
							variant="h5"
						>
							{getTranslation(
								"Total Capacity",
								"Total Capacity",
								"Total Capacity"
							) + ":"}

							{totalCapacity}
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<MyTable
				auto
				dense
				loading={loadingMaxCapacity}
				colums={columns}
				page={queryParams.pageNumber}
				rowsPerPage={queryParams.pageSize}
				rows={maxCapacities}
				count={maxCapacities.length}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				sortBy="persons"
				viewSearch
				viewCreate
				viewEdit
				onEdit={(row) => {
					setIsEdit(true);
					setTableData(row);
					setShowCreateTableDialog(true);
				}}
				onCreate={() => {
					setIsEdit(false);
					setTableData({
						id: null,
						persons: 0,
						quantity: 0,
					});
					setShowCreateTableDialog(true);
				}}
				actions={[
					{
						component: (row) => (
							<DeleteButton
								onClick={() => {
									setOpenDeleteModal(true);
									setTableData(row);
								}}
							/>
						),
					},
				]}
			/>
			<AddTable
				open={showCreateTableDialog}
				handleClose={setShowCreateTableDialog}
				editTable={isEdit}
				tableData={tableData}
				params={queryParams}
				setParams={setQueryParams}
			/>
			<AlertDialog
				open={openDeleteModal}
				onClose={() => setOpenDeleteModal(false)}
				deleting={isDeleting}
				onSubmit={handleDelete}
			/>
		</Card>
	);
};
