import {
  LOADING_MAX_CAPACITY,
  POSTING_MAX_CAPACITY,
  SET_MAX_CAPACITY,
  SET_MAX_CAPACITY_DAYTIME,
  SET_MAX_CAPACITY_TOTAL_COUNT,
} from "../constants/types";

const initialState = {
  totalCount: 0,
  dayTime: 0,
  maxCapacity: [],
  loadingMaxCapacity: false,
  postingMaxCapacity: false,
};

const maxCapacityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAX_CAPACITY:
      return {
        ...state,
        maxCapacity: action.payload,
      };
    case LOADING_MAX_CAPACITY:
      return {
        ...state,
        loadingMaxCapacity: action.payload,
      };
    case POSTING_MAX_CAPACITY:
      return {
        ...state,
        postingMaxCapacity: action.payload,
      };
    case SET_MAX_CAPACITY_DAYTIME:
      return {
        ...state,
        dayTime: action.payload,
      };
    case SET_MAX_CAPACITY_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    default:
      return state;
  }
};

export default maxCapacityReducer;
