import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
	Button,
	Collapse,
	colors,
	ListItem,
	makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
	item: {
		display: "block",
		paddingTop: 0,
		paddingBottom: 0,
		color: theme.palette.common.black,
	},
	itemLeaf: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
		color: theme.palette.common.black,
		marginTop: "0.8rem",
	},
	button: {
		color: theme.palette.common.white,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
	},
	buttonLeaf: {
		color: theme.palette.common.white,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		fontWeight: theme.typography.fontWeightRegular,
		"&.depth-0": {
			"& $title": {
				fontWeight: theme.typography.fontWeightMedium,
				color: theme.palette.common.black,
			},
		},
		"&:hover": {
			border: `1px solid ${theme.palette.primary.main}`,
			backgroundColor: "white",

			"& $title": {
				color: theme.palette.primary.main,
			},
		},
	},
	icon: {
		display: "flex",
		alignItems: "center",
		marginRight: "1.5rem",
		color: theme.palette.common.white,
	},
	title: {
		marginRight: "auto",
		color: theme.palette.common.white,
	},
	active: {
		backgroundColor: "white",
		color: "red",
		"& $title ": {
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.primary.main,
		},
		"& $icon": {
			color: theme.palette.primary.main,
		},
	},
	collapse: {},
}));

function NavItem({
	title,
	href,
	depth,
	children,
	icon: Icon,
	className,
	open: openProp,
	info: Info,
	component,
	...rest
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(openProp);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem
				className={clsx(classes.item, className)}
				disableGutters
				key={title}
				{...rest}
			>
				<Button className={classes.button} onClick={handleToggle} style={style}>
					{Icon && <Icon className={classes.icon} size="20" />}
					<span className={classes.title}>{title}</span>
					{open ? (
						<ExpandLessIcon className="" color="inherit" />
					) : (
						<ExpandMoreIcon color="inherit" />
					)}
				</Button>
				<Collapse className={classes.collapse} in={open}>
					{children}
				</Collapse>
			</ListItem>
		);
	}
	const Component = component;
	return (
		<ListItem
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			key={title}
			{...rest}
		>
			<Button
				activeClassName={classes.active}
				className={classes.buttonLeaf}
				component={RouterLink}
				style={style}
				to={href}
			>
				{Icon && <Icon className={classes.icon} size="50" />}
				{component ? (
					<Component />
				) : (
					<span className={classes.title}>{title}</span>
				)}
				{Info && <Info className={""} />}
			</Button>
		</ListItem>
	);
}

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	icon: PropTypes.any,
	info: PropTypes.any,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	open: false,
};

export default NavItem;
