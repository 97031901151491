import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Box,
	Grid,
	TextField,
	IconButton,
	InputAdornment,
	CardHeader,
	Typography,
} from "@material-ui/core";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Formik } from "formik";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useRef } from "react";
import CloudUpload from "@material-ui/icons/CloudUpload";
import _ from "lodash";
import { Get, Post } from "../../../../actions";
import {
	Get_AllMenuFile_URL,
	Get_SystemSetting_URL,
	Post_UpsertSystemSetting_URL,
} from "../../../../constants/apiUrls";
import { UploadFiles } from "../../../../components/Upload";
import { Label } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardComponent from "../../../../components/Card";
const Information = () => {
	const { enqueueSnackbar } = useSnackbar();

	const [systemSetting, setSystemSetting] = useState([]);
	const initialValues = systemSetting
		? systemSetting
		: {
				title: "",
				subTitle: "",
				description: "",
		  };
	const validationSchema = Yup.object().shape({});
	useEffect(() => {
		getSystemSetting();
	}, []);

	const getSystemSetting = () => {
		Get(
			{},
			Get_SystemSetting_URL,
			null,
			(res) => {
				setSystemSetting(res?.data);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const onSubmit = (values, actions) => {
		actions.setSubmitting(true);

		Post(
			values,
			Post_UpsertSystemSetting_URL,
			null,
			(res) => {
				actions.setSubmitting(false);
			},
			(err) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin."
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					isSubmitting,
					setFieldValue,
					handleSubmit,
				}) => (
					<form onSubmit={handleSubmit}>
						<CardComponent
							title={getTranslation(
								"Basic Information",
								"Basic Information",
								"Basic Information"
							)}
						>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item sm={3} md={3} lg={3} xl={3}>
										<TextField
											variant="outlined"
											fullWidth
											id="title"
											label={getTranslation("Title", "Title", "Title")}
											name="title"
											type="text"
											size="small"
											InputLabelProps={{
												shrink: true,
											}}
											value={values.title}
											onChange={handleChange}
											error={touched.title && Boolean(errors.title)}
											helperText={touched.title && errors.title}
										/>
									</Grid>
									<Grid item sm={3} md={3} lg={3} xl={3}>
										<TextField
											variant="outlined"
											fullWidth
											id="subTitle"
											label={getTranslation(
												"Sub Title",
												"Sub Title",
												"Sub Title"
											)}
											name="subTitle"
											type="text"
											size="small"
											InputLabelProps={{
												shrink: true,
											}}
											value={values.subTitle}
											onChange={handleChange}
											error={touched.subTitle && Boolean(errors.subTitle)}
											helperText={touched.subTitle && errors.subTitle}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<TextField
											variant="outlined"
											fullWidth
											id="description"
											label={getTranslation(
												"Description",
												"Description",
												"Description"
											)}
											name="description"
											type="text"
											multiline // Enable multiline
											size="small"
											InputLabelProps={{ shrink: true }}
											value={values.description}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										justifyContent: "flex-end",
										marginTop: "1rem",
									}}
								>
									<ButtonWithLoading
										title={
											systemSetting
												? getTranslation("Update", "Update", "Update")
												: getTranslation("Save", "Save", "Save")
										}
										type="submit"
										variant="contained"
										color="primary"
										textColor="white"
										loading={isSubmitting}
									/>
								</Box>
							</CardContent>
						</CardComponent>
					</form>
				)}
			</Formik>
		</>
	);
};

export default Information;
