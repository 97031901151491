/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
	Box,
	Drawer,
	List,
	ListSubheader,
	Divider,
	useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchPath } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import navConfig from "./Menu";
import { Logo, TransparentLogo } from "../../components/Logo";
import useStyles from "../../assests/styles/layout/sidebar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

function renderNavItems({ items, depth, pathname, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, pathname, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				key={key}
				info={item.info}
				open={Boolean(open)}
				title={item.title}
				component={item.component}
			>
				<Box pl={5}>
					{renderNavItems({
						depth: depth + 3,
						pathname,
						items: item.items,
					})}
				</Box>
			</NavItem>
		);
	} else {
		acc.push(
			<NavItem
				open={true}
				depth={depth}
				href={item.href}
				icon={item.icon}
				key={key}
				info={item.info}
				title={item.title}
				component={item.component}
			/>
		);
	}

	return acc;
}

function Sidebar({ openMobile, setOpenMobile }) {
	const classes = useStyles();
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const handleDrawerClose = () => {
		setOpenMobile(false);
	};

	useEffect(() => {
		if (isMobile) {
			setOpenMobile(false);
		} else {
			setOpenMobile(true);
		}
	}, [isMobile]);

	const content = (
		<Box
			height="100%"
			display="flex"
			role="presentation"
			flexDirection="column"
			style={{ backgroundColor: theme.palette.primary.main }}
		>
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Box mt={-2} height={"10rem"} display="flex" justifyContent="center">
					<RouterLink
						to="/"
						// style={{ width: "7rem", height: "5rem", marginTop: "4rem" }}
						style={{
							height: "7rem",
							marginTop: "2rem",
							marginRight: "20px",
						}}
					>
						<Logo />
					</RouterLink>
				</Box>

				<Divider style={{ backgroundColor: "white" }} />
				<Box px={2.8} py={1.5}>
					{navConfig.map((config) => (
						<List
							key={config.subheader}
							subheader={
								<ListSubheader disableGutters disableSticky>
									{config.subheader}
								</ListSubheader>
							}
						>
							{renderNavItems({
								items: config.items,
								pathname: location.pathname,
							})}
						</List>
					))}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={openMobile}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				{content}
				<div className={classes.drawerHeader}>
					<p style={{ marginRight: "35px" }}>Version 1.5</p>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? (
							<ChevronLeftIcon />
						) : (
							<ChevronRightIcon />
						)}
					</IconButton>
				</div>
			</Drawer>
		</>
	);
}
export default Sidebar;
