import { Typography } from "@material-ui/core";
import { RiSettings2Fill } from "react-icons/ri";
// import SettingsIcon from '@material-ui/icons/Settings';
import CustomersIcon from "../../components/CustomersIcon";
import DashboardIcon from "../../components/DashboardIcon";
import ReservationIcon from "../../components/ReservationIcon";
import { getTranslation } from "../../heplers/translationHelper";
import SettingIcon from "../../components/SettingIcon";
const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: getTranslation("Dashboard", "Dashboard", "Dashboard"),
        icon: DashboardIcon,
        href: "/dashboard",
      },
      {
        title: getTranslation("Reservations", "Reservations", "Reservations"),
        icon: ReservationIcon,
        href: "/reservations/current",
      },
      {
        title: getTranslation("Customers", "Customers", "Customers"),
        icon: CustomersIcon,
        href: "/customers",
      },
      {
        title: getTranslation("Settings", "Settings", "Settings"),
        icon: SettingIcon,
        href: "/settings/table-management",
      },

      // {
      //   title: "Account",
      //   icon: RiAccountPinBoxLine,
      //   href: "/account",
      //   items: [
      //     {
      //       title: "My Account",
      //       href: "/account/profile",
      //     },

      //   ],
      // },
    ],
  },
];

export default navConfig;
