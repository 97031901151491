import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { MyTable } from "../../../../components";
import {
  DeleteButton,
  EditButton,
} from "../../../../components/ButttonsWithIcons";
import { generatedId } from "../../../../utils/generateId";
import { useDaysOffStyles } from "./daysOffStyles";
import clsx from "clsx";
import AddDaysOff from "./addDaysOff";
import { daysConstant } from "../../../../constants/general";
import AlertDialog from "../../../../components/DeleteDialog";
import { Get } from "../../../../actions";
import { Delete_DaysOff_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import { format } from "../../../../heplers/format";

const columns = [
  {
    id: "startDate",
    label: getTranslation("Start Date","Start Date","Start Date"),
    format: "date",
  },

  {
    id: "endDate",
    label: getTranslation("End Date","End Date","End Date"),
    component:({row})=>row.dayOffType==1? <Typography>{format("date",row.endDate)}</Typography>:"-"
  },
  {
    id: "day",
    label: getTranslation("Day","Day","Day"),
    component:({row})=>{
      return(
      getTranslation(row?.day,row?.day,row?.day)    
      )
    }
  },
  {
    id: "comments",
    label: getTranslation("Comments","Comments","Comments"),
  },
  {
    label: getTranslation("Action","Action","Action"),
    id: "actions",
    align: "right",
  },
];

const DaysOffTable = ({ isLoading, daysOff, params, setParams }) => {
  const [showModal, setShowModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    day: daysConstant.find(x=>x.id==new Date().getDay()).title,
    comments: "",
    dayOffType:0
  });

  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useDaysOffStyles();

  //handlers
  const handleChangePage = (e, newPage) => {
    setParams({ ...params, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    Get(
      { id: formData.id },
      Delete_DaysOff_URL,
      null,
      (res) => {
        setIsDeleting(false);
        enqueueSnackbar(
          getTranslation(
            "Deleted Successfully",
            "Deleted Successfully",
            "Deleted Successfully"
          ),
          {
            variant: "success",
          }
        );
        setOpenDeleteModal(false);
        setParams({ ...params, pageNumber: 0 });
      },
      (err) => {
        setIsDeleting(false);
        enqueueSnackbar(err.data, {
          variant: "error",
        });
      }
    );
  };

  return (
    <div className={clsx(classes.tableWrapper)}>
      <MyTable
        auto
        dense
        title={getTranslation("Days Off","Days Off","Days Off")}
        colums={columns}
        loading={isLoading}
        page={params.pageNumber}
        rowsPerPage={params.pageSize}
        rows={daysOff}
        count={daysOff.length}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy="persons"
        viewCreate
        viewSearch
        viewEdit
        onEdit={(row) => {
          setIsEdit(true);
          setFormData(row);
          setShowModal(true);
        }}
        onCreate={() => {
          setFormData({
            startDate: new Date(),
            endDate:  new Date(),
            day: daysConstant.find(x=>x.id==new Date().getDay()).title,
            comments: "",
            dayOffType:0
          });
          setShowModal(true);
        }}
        actions={[
          {
            component: (row) => (
              <DeleteButton
                onClick={() => {
                  setOpenDeleteModal(true);
                  setFormData(row);
                }}
              />
            ),
          },
        ]}
      />
      <AddDaysOff
        selectedFormData={formData}
        setSelectedFormData={setFormData}
        isEdit={isEdit}
        openDialog={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        params={params}
        setParams={setParams}
      />
      <AlertDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deleting={isDeleting}
        onSubmit={handleDelete}
      />
    </div>
  );
};

export default DaysOffTable;
