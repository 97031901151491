/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import userReservationStyles from "../../../assests/styles/views/public/userReservation";
import { getTranslation } from "../../../heplers/translationHelper";
import authService from "../../../utils/authUtils";
import LanguageSelector from "../component/languageSelector";
import UserReservationLayout from "../component/Layout";
import Navbar from "../component/Navbar";
import { ReservationForm } from "../component/ReservationForm";
import { Get_Reservation_URL, Get_Logo_URL } from "../../../constants/apiUrls";
import { useState } from "react";
import { Get, GetFile } from "../../../actions";
import LogoFile1 from "../../../assests/images/restobizLogo.png";

const UserReservation = ({ enableLogo }) => {
	const params = useParams();
	const { customerId, primaryColor, secondaryColor, reservationId } = params;
	const setCustomerId = authService.setCustomerId;
	const [selectedReservation, setSelectedReservaton] = useState();
	const [loading, setLoading] = useState(false);
	const [src, setSrc] = useState("");

	useEffect(() => {
		if (
			localStorage.getItem("customerId") != null &&
			localStorage.getItem("token") != null
		) {
			if (!iniFrame()) {
				authService.logout();
				window.location.reload();
			}
		} else setCustomerId(customerId);
	}, []);
	useEffect(() => {
		if (reservationId) {
			setLoading(true);

			Get(
				{
					id: reservationId,
				},
				Get_Reservation_URL,
				null,
				(res) => {
					res.data.oldPersons = res?.data.persons;
					setSelectedReservaton(res.data);
					setLoading(false);
				},
				(err) => {
					setLoading(false);
				}
			);
		}
	}, [reservationId]);

	useEffect(() => {
		setLoading(true);
		Get(
			{},
			Get_Logo_URL,
			null,
			(res) => {
				setLoading(false);
				setSrc(GetFile(res.data));
			},
			(err) => {
				setLoading(false);
			}
		);
	}, []);
	const classes = userReservationStyles({
		primaryColor: "#" + primaryColor,
		secondaryColor: "#" + secondaryColor,
	});

	const iniFrame = () => {
		return authService.isAuthenticated();
	};

	return (
		<>
			<UserReservationLayout
				classes={classes}
				disabled={iniFrame()}
				enableLogo={enableLogo}
			>
				<div className={classes.formWrapper}>
					<Grid item sm={12} xs={12}>
						{enableLogo ? (
							src ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										marginTop: "10px",
									}}
								>
									<div
										style={{
											width: "200px",
											height: "150px",
										}}
									>
										<img
											alt="Logo"
											src={src}
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								</div>
							) : (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "10px",
										width: "100%",
									}}
								>
									<img alt="Logo" src={LogoFile1} style={{ width: "25%" }} />
								</div>
							)
						) : null}
					</Grid>
					<ReservationForm
						disabled={iniFrame()}
						classes={classes}
						isEdit={selectedReservation}
						selectedData={selectedReservation}
						onlineReservationRefresh={() => window.location.reload()}
					/>
				</div>
			</UserReservationLayout>
		</>
	);
};

export default UserReservation;
