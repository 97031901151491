import { ToggleButton } from "@material-ui/lab";
import { StyledToggleButtonGroup } from "../../../../components";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness6Icon from "@material-ui/icons/Brightness6";
import { Box, Card, CardContent, makeStyles } from "@material-ui/core";
import { backdropClasses } from "@mui/material";

const style = makeStyles((theme) => ({
  buttonCont: {
    "& :hover": {
      backgroundColor: "white",
    },
  },
}));

export const DayTimeSelection = ({ params, setParams }) => {
  const handleToggle = (e, val) => {
    if(val==0 || val==1)
      setParams({ ...params, dayTime: val });
  };

  return (
    
    <Card>
      <CardContent>
      <Box display="flex" justifyContent="center">

        <StyledToggleButtonGroup
          exclusive
          value={params.dayTime}
          onChange={handleToggle}
        >
          <ToggleButton
            value={0}
            color="secondary"
            // className={style.buttonCont}
          >
            <WbSunnyIcon/>
          </ToggleButton>
          <ToggleButton value={1}>
            <Brightness6Icon/>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      </CardContent>
    </Card>
  );
};
