//Breadcrumbs
export const Add_BreadCrumb = "Add_BreadCrumb";
export const Replace_And_Add_BreadCrumb = "Replace_And_Add_BreadCrumb";
export const Clear_All_BreadCrumb = "Clear_All_BreadCrumb";

export const SET_USER_DATA = "SET_USER_DATA";

//Activities Types
export const GET_ACTIVITY_TYPE = "GET_ACTIVITY_TYPE";
export const GET_ACTIVITY_TYPES = "GET_ACTIVITY_TYPES";
export const ACTIVITY_TYPES_LOADING = "ACTIVITY_TYPES_LOADING";
export const ACTIVITY_TYPES_ERROR = "ACTIVITY_TYPES_ERROR";
export const ACTIVITY_TYPE_ERROR = "ACTIVITY_TYPE_ERROR";
export const EDIT_ACTIVITY_TYPE = "EDIT_ACTIVITY_TYPE";

//Contacts
export const GET_AllContacts = "GET_Contact";
export const GET_AllContacts_ERROR = "GET_Contact_ERROR";
export const GET_Contacts = "GET_ContactS";
export const Contacts__LOADING = "ContactS__LOADING";
export const Contacts_ERROR = "ContactS_ERROR";
export const EDIT_Contact = "EDIT_Contact";
export const Get_Contact_BelongsTo = "Get_Contact_BelongsTo";

//Companies
export const GET_AllCompanies = "GET_Companies";
export const GET_AllCompanies_ERROR = "GET_Companies_ERROR";
export const GET_Company = "GET_Company";
export const Companies__LOADING = "Companies__LOADING";
export const Company_ERROR = "Company_ERROR";
export const EDIT_Company = "EDIT_Company";
export const GET_AllGeneralPartners = "GET_AllGeneralPartners";

//Company Group
export const GET_AllCompanyGroups = "GET_CompanyGroups";
export const CompanyGroups__LOADING = "CompanyGroups__LOADING";
export const GET_AllCompanyGroups_ERROR = "GET_CompanyGroups_ERROR";

//Legal Form
export const GET_AllLegalForm = "GET_LegalForm";
export const LegalForm__LOADING = "LegalForm__LOADING";
export const GET_AllLegalForm_ERROR = "GET_LegalForm_ERROR";

//Sub Menus
export const SET_SUB_MENU = "SET_SUB_MENU";
export const CLEAR_SUB_MENU = "CLEAR_SUB_MENU";

//User Reservations
export const SET_USER_RESERVATION = "SET_USER_RESERVATION";
export const GET_USER_RESERVATION = "GET_USER_RESERVATION";
export const GET_TIME_SLOT = "GET_TIME_SLOT";
export const LOADING_USER_RESERVATION = "LOADING_USER_RESERVATION";
export const DELETING_USER_RESERVATION = "DELETING_USER_RESERVATION";
export const LOADING_TIME_SLOT = "LOADING_TIME_SLOT";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_DAYTIME = "SET_DAYTIME";
export const SET_DAY = "SET_DAY";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_RESERVATION_STATS = "SET_RESERVATION_STATS";
export const SET_SELECTED_RESERVATION = "SET_SELECTED_RESERVATION";
export const SET_ERRORS = "SET_ERRORS";

//Customers
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const SET_CUSTOMER_PAGE_NUMBER = "SET_CUSTOMER_PAGE_NUMBER";
export const SET_CUSTOMER_TOTAL_COUNT = "SET_CUSTOMER_TOTAL_COUNT";

//Max Capacity
export const SET_MAX_CAPACITY = "SET_MAX_CAPACITY";
export const LOADING_MAX_CAPACITY = "LOADING_MAX_CAPACITY";
export const SET_MAX_CAPACITY_DAYTIME = "SET_MAX_CAPACITY_DAYTIME";
export const SET_MAX_CAPACITY_TOTAL_COUNT = "SET_MAX_CAPACITY_TOTAL_COUNT";
export const POSTING_MAX_CAPACITY = "POSTING_MAX_CAPACITY";

// Theme
export const SET_PRIMARY_COLOR = "PRIMARY_COLOR";
export const SET_SECONDARY_COLOR = "SECONDARY_COLOR";

// Reservation

export const SET_LASTUPDATEOFRESERVATIONS="LASTUPDATEOFRESERVATIONS"
