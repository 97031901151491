import {
	Badge,
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	withStyles,
} from "@material-ui/core";
import { DatePicker } from "../../../../components/DatePicker";
import React, { useEffect, useState } from "react";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import TimeSlots from "./timeSlot";
import * as Yup from "yup";
import { Formik } from "formik";
import {
	Get_All_DaysOff_URL,
	Get_CalendarDaysOff_URL,
	Update_Reservation_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router-dom";
import { PickersDay } from "@mui/x-date-pickers";
import { Get } from "../../../../actions";

const ReservationFormOne = ({
	isEdit,
	selectedReservation,
	setSelectedReservation,
	selectedData,
	activeStep,
	setActiveStep,
	addReservation,
	classes,
	defaultPrimaryColor,
	disabled,
}) => {
	// const classes = userReservationStyles();
	const { primaryColor, secondaryColor } = useParams();
	const [verificationStatus, setVerifcationStatus] = useState("Ok");
	const { enqueueSnackbar } = useSnackbar();

	const validationSchema = Yup.object().shape({
		reservationDate: Yup.date().test(
			"reservation_Date",
			getTranslation(
				"Selected date is off day",
				"Selected date is off day",
				"Selected date is off day"
			),
			function (val) {
				return !isOffDay(val);
			}
		),
		persons: Yup.number().min(
			1,
			getTranslation(
				"Please select at least one person",
				"Please select at least one person",
				"Please select at least one person"
			)
		),
		purposeOfReservation: Yup.number(),
	});

	const CssRadio = withStyles({
		colorSecondary: {
			color: "gray",
			"&$checked": {
				color: "#" + primaryColor,
			},
		},
		checked: {},
	})(Radio);
	const [loadingDaysOff, setLoadingDaysOff] = useState(false);
	const [daysOff, setDaysOff] = useState([]);
	const getDaysOff = (payload) => {
		setLoadingDaysOff(true);

		Get(
			payload,
			Get_CalendarDaysOff_URL,
			null,
			(res) => {
				setDaysOff(res.data);
			},
			(err) => {
				setLoadingDaysOff(false);
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};
	useEffect(() => {
		if (!disabled) {
			const currentDate = new Date();
			while (isOffDay(currentDate)) {
				currentDate.setDate(currentDate.getDate() + 1);
			}
			setSelectedReservation({
				...selectedReservation,
				reservationDate: new Date(currentDate),
			});
			setLoadingDaysOff(false);
		}
		setLoadingDaysOff(false);
	}, [daysOff]);

	useEffect(() => {
		if (!disabled) {
			const date = new Date();
			const to = new Date();
			to.setMonth(date.getMonth() + 2, 1);
			getDaysOff({ from: date, to: to });
		}
	}, []);
	const daysOffByday = daysOff.filter((x) => x.dayOffType == 0);
	const daysOffByperiod = daysOff.filter((x) => x.dayOffType == 1);

	const isOffDay = (date) => {
		// Check if it is non period date
		for (let index = 0; index < daysOffByday.length; index++) {
			const r = moment(daysOffByday[index].startDate).isSame(date, "date");
			if (r) return r;
			// return false
		}

		for (let index = 0; index < daysOffByperiod.length; index++) {
			const r =
				moment(date).isSameOrAfter(daysOffByperiod[index].startDate, "date") &&
				moment(date).isSameOrBefore(daysOffByperiod[index].endDate, "date");
			if (r) return r;
			// return false
		}
	};

	const updateVerificationStatus = (status) => {
		setVerifcationStatus(status);
	};
	const EditResvation = () => {
		var ResStartDate = new Date(selectedData?.timeSlots[0]?.startDateTime);
		var ResendDate = new Date(selectedData?.timeSlots[0]?.endDateTime);
		return {
			onEditStartDateTime: moment(ResStartDate).format("HH:mm"),
			DurationOfTimeSlot: moment(ResendDate).format("HH:mm"),
		};
	};

	if (isEdit == true) {
		EditResvation();
	}

	const [selectedPersons, setSelectedPersons] = useState(1);
	useEffect(() => {
		if (isEdit) setSelectedPersons(selectedData.persons);
	}, [selectedData]);

	return (
		<div>
			{loadingDaysOff ? (
				<CircularProgress />
			) : (
				<div className={classes.inputsWrapper}>
					<Formik
						// enableReinitialize={loadingDaysOff}
						validationSchema={validationSchema}
						initialValues={isEdit ? selectedData : selectedReservation}
						onSubmit={(values, actions) => {
							actions.setSubmitting(true);

							if (isEdit) {
								if (values.haveSmallKids && values.kids < 1) {
									actions.setSubmitting(false);
									enqueueSnackbar(
										getTranslation(
											"Minimum of 1 kid required",
											"Minimum of 1 kid required",
											"Minimum of 1 kid required"
										),
										{
											variant: "error",
										}
									);
									return;
								}

								const payload = {
									id: values.id,
									allergies: values.allergies,
									haveSmallKids: values.haveSmallKids,
									kids: values.kids,
									persons: values.persons,
									timeSlotId: values.timeSlotId,
									timeSlots: values.timeSlots,
									reservationDate: moment(values.reservationDate).format(
										"MM-DD-YYYY"
									),
									purposeOfReservation: values.purposeOfReservation,
									customer: {
										fullName: values.fullName,
										email: values.email,
										phone: values.phone,
										subscribeForNews: values.subscribeForNews,
									},
								};
								addReservation(payload, Update_Reservation_URL, actions);
							} else {
								if (!values.timeSlotId) {
									actions.setSubmitting(false);
									enqueueSnackbar(
										getTranslation(
											" Please Select a time slot",
											" Please Select a time slot ",
											" Please Select a time slot "
										),
										{
											variant: "error",
										}
									);
									return;
								}
								const data = { ...selectedReservation, ...values };
								setSelectedReservation(data);
								setActiveStep(activeStep + 1);
							}
						}}
						validateOnChange={false}
					>
						{({
							values,
							setValues,
							setFieldValue,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
						}) => {
							return (
								<form onSubmit={handleSubmit} className={classes.form}>
									<>
										<div className={classes.inputConts}>
											<TextField
												disabled={disabled}
												variant="outlined"
												required
												fullWidth
												label={getTranslation(
													"Number of Persons",
													"Number of Persons",
													"Number of Persons"
												)}
												name="persons"
												type="number"
												size="medium"
												value={selectedPersons}
												onBlur={(e) => {
													setFieldValue("persons", e.target.value);
													// if(isEdit){
													//   setFieldValue("timeSlotId",null)
													// }
													// if(!isEdit)
													setFieldValue("timeSlotId", null);
												}}
												onChange={(e) => {
													if (isEdit) {
														if (e.target.value <= selectedData.persons)
															setSelectedPersons(e.target.value);
														else
															enqueueSnackbar(
																"cannot increase the number of Person",
																{ variant: "error" }
															);
													} else setSelectedPersons(e.target.value);
												}}
												error={touched.persons && Boolean(errors.persons)}
												helperText={touched.persons && errors.persons}
												InputProps={{ inputProps: { min: 1 } }}
											/>
											<DatePicker
												className={classes.datePicker}
												required
												disabled={disabled ? disabled : isEdit ? true : false}
												size="medium"
												label={getTranslation("Date", "Date", "Date")}
												value={values.reservationDate}
												onChange={(val) => {
													setFieldValue("reservationDate", val);
													setFieldValue("timeSlotId", "");
												}}
												id="date"
												name="reservationDate"
												renderDay={(
													day,
													selectedDate,
													isInCurrentMonth,
													dayComponent
												) => {
													// You can also use our internal <Day /> component
													return (
														<div
															style={
																isOffDay(day)
																	? { textDecoration: "line-through" }
																	: null
															}
														>
															{dayComponent}
														</div>
													);
												}}
												disablePast
												shouldDisableDate={(day) => isOffDay(day)}
												error={
													touched.reservationDate &&
													Boolean(errors.reservationDate)
												}
												helperText={
													touched.reservationDate && errors.reservationDate
												}
												// onMonthChange={(date)=>{
												//   const nextMonth=new Date();
												//   nextMonth.setMonth(values.res)
												//   getDaysOff({from:})}}
											/>
										</div>
										{/* <div className={classes.radioWrapper}>
                    <FormLabel id="purposeOfReservation">
                      {getTranslation(
                        "Purpose of Reservation",
                        "Purpose of Reservation",
                        "Purpose of Reservation"
                      )}
                    </FormLabel>
                    <RadioGroup
                      row
                      id="purposeOfReservation"
                      name="purposeOfReservation"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={values.purposeOfReservation}
                      onChange={(val) => {
                        const { value } = val.target;
                        setFieldValue("purposeOfReservation", value);
                      }}
                    >
                      <FormControlLabel
                        label={getTranslation("For Eating","For Eating","For Eating")}
                        value={0}
                        control={
                          <CssRadio
                            checked={values.purposeOfReservation == 0}
                            
                          />
                        }
                      />
                      <FormControlLabel
                        label={getTranslation("For Eating & Spending Time","For Eating & Spending Time","For Eating & Spending Time")}
                        value={1}
                        control={
                          <CssRadio
                            checked={values.purposeOfReservation == 1}
                          />
                        }
                      />
                    </RadioGroup>
                  </div> */}
										{isEdit && (
											<div>
												<TextField
													className={classes.input}
													variant="outlined"
													multiline
													minRows={5}
													fullWidth
													label={getTranslation(
														"Give information of allergies and Intolerance",
														"Give information of allergies and Intolerance",
														"Give information of allergies and Intolerance"
													)}
													name="allergies"
													type="number"
													size="small"
													value={values.allergies}
													onChange={handleChange}
													error={touched.allergies && Boolean(errors.allergies)}
													helperText={touched.allergies && errors.allergies}
												/>
												{/* <FormControlLabel
                        label={"Select if there will be kids < 4 years old"}
                        name="haveSmallKids"
                        value={values.haveSmallKids}
                        onChange={(val) => {
                          const { checked } = val.target;
                          setFieldValue("haveSmallKids", checked);
                        }}
                        control={
                          <Checkbox
                            checked={values.haveSmallKids}
                            // className={classes.checkBox}
                            style={{ color: defaultPrimaryColor }}

                          />
                        }
                      /> */}
											</div>
										)}
										{isEdit && (
											<TextField
												variant="outlined"
												required
												fullWidth
												label={getTranslation(
													"Number of Kids",
													"Number of Kids",
													"Number of Kids"
												)}
												name="kids"
												type="number"
												size="small"
												value={values.kids}
												onChange={handleChange}
												error={touched.kids && Boolean(errors.kids)}
												helperText={touched.kids && errors.kids}
												InputProps={{ inputProps: { min: 0, max: 100 } }}
												style={{ marginTop: "1rem" }}
											/>
										)}
										<>
											{isEdit ? (
												<button
													style={{ marginTop: "1rem" }}
													type="button"
													className={classes.timeBtn}
													disabled={true}
												>
													<Typography>
														{getTranslation(
															"Current Time Slot",
															"Current Time Slot",
															"Current Time Slot"
														)}

														<Divider />
														{EditResvation().onEditStartDateTime}
														<Divider />
														{EditResvation().DurationOfTimeSlot}
													</Typography>
												</button>
											) : (
												""
											)}
										</>
										{!isEdit ? (
											<div style={{ marginTop: "1rem" }}>
												<FormLabel id="selectTimeSlot">
													{getTranslation(
														"Select Time Slot / Arriving time",
														"Select Time Slot / Arriving time",
														"Select Time Slot / Arriving time"
													)}
												</FormLabel>
												{!disabled ? (
													<TimeSlots
														isEdit={isEdit}
														selectedReservation={selectedData}
														setSelectedReservation={(reservation) => {
															setSelectedReservation(reservation);
															setFieldValue("timeSlots", reservation.timeSlots);
														}}
														formData={values}
														setTimeSlotId={setValues}
														setVerifcationStatus={updateVerificationStatus}
														classes={classes}
													/>
												) : null}
											</div>
										) : (
											""
										)}
										<br />
										<ButtonWithLoading
											type="submit"
											disabled={
												disabled
													? disabled
													: verificationStatus == "Not Ok"
													? true
													: false
											}
											loading={isSubmitting}
											title={
												isEdit
													? getTranslation("Submit", "Submit", "Submit")
													: getTranslation("Next", "Next", "Next")
											}
											variant="contained"
											style={
												isSubmitting == false
													? {
															backgroundColor: "#" + primaryColor,
															color: "#" + secondaryColor,
															marginTop: "1rem",
													  }
													: {
															backgroundColor: "gray",
															color: "#" + primaryColor,
															marginTop: "1rem",
													  }
											}
										/>
									</>
								</form>
							);
						}}
					</Formik>
				</div>
			)}
		</div>
	);
};

export default ReservationFormOne;
