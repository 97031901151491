import { makeStyles } from "@material-ui/core";

export const useCustomerManagementStyles = makeStyles((theme) => ({
  formWrapper: {
    width: "100%",
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "30px",
  },
  formHeader: {
    backgroundColor: theme.palette.primary.main,
    height: "3rem",
    borderRadius: "30px 30px 0 0",
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    color: "white",
  },
  inputCont: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr))",
    gap: "2rem",
    rowGap: "1rem",
    marginTop: "1rem",
  },
  form: {
    padding: "2rem",
  },
  btnCont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  loaderCont: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "2000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableWrapper: {
    marginTop: "1rem",
  },
  radio: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
}));
