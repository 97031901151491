import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { generatedId } from "../../../../utils/generateId";
import { useDaysOffStyles } from "../daysOffTable/daysOffStyles";
import clsx from "clsx";
import { MyTable } from "../../../../components";
import {
  DeleteButton,
  EditButton,
} from "../../../../components/ButttonsWithIcons";
import AddTimeSlotModal from "./addTimeSlotModal";
import { useSnackbar } from "notistack";
import { Get } from "../../../../actions";
import { Delete_TimeSlots_URL } from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import AlertDialog from "../../../../components/DeleteDialog";

import { format } from "../../../../heplers/format";

const columns = [
  {
    id: "startDateTime",
    label: getTranslation("From", "From", "From"),
    format: "time",
  },

  {
    id: "endDateTime",
    label: getTranslation("To", "To", "To"),
    format: "time",
  },
  {
    id: "maxGroup",
    label: getTranslation("Max Group", "Max Group", "Max Group"),
  },
  {
    id: "maxPersonPerGroup",
    label: getTranslation("Max Person", "Max Person", "Max Person"),
  },
  {
    id: "comments",
    label: getTranslation("Comments", "Comments", "Comments"),
  },
  {
    label: getTranslation("Action", "Action", "Action"),
    id: "actions",
    align: "right",
  },
];

const TimeSlotTable = ({ params, setParams, timeSlots, refreshTimeSlots, loadingTimeSlots }) => {
  const classes = useDaysOffStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    startDateTime: new Date(),
    endDateTime: new Date(),
    maxGroup: 0,
    maxPersonPerGroup: 0,
    comments: "",
    selectedDays: []

  });
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);

  //handlers
  const handleChangePage = (e, newPage) => {
    setParams({ ...params, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value });
  };

  const handleAddTimeSlot = (type, selectedData) => {
    setShowTimeSlotModal(true);
    if (type === "edit") {
      setIsEdit(true);
      setFormData(selectedData);
    } else {
      setFormData({
        startDateTime: new Date(),
        endDateTime: new Date(),
        maxGroup: 0,
        maxPersonPerGroup: 0,
        comments: "",
        selectedDays: []
      });
    }

  };

  const handleDelete = () => {
    setIsDeleting(true);
    Get(
      { id: formData.id },
      Delete_TimeSlots_URL,
      null,
      (res) => {
        setIsDeleting(false);
        enqueueSnackbar(
          getTranslation(
            "Deleted Successfully",
            "Deleted Successfully",
            "Deleted Successfully"
          ),
          {
            variant: "success",
          }
        );
        setOpenDeleteModal(false);
        // let date = new Date();
        // date = date.setDate(params.day + 1);
        if (refreshTimeSlots)
          refreshTimeSlots()
        setParams({ ...params, day: params.day });
      },
      (err) => {
        setIsDeleting(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
              "Something went wrong. Please Try Again.",
              "Something went wrong. Please Try Again.",
              "Something went wrong. Please Try Again."
            ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  return (
    <div className={clsx(classes.tableWrapper)}>
      <MyTable
        auto
        dense
        title={getTranslation("Time Slots", "Time Slots", "Time Slots")}
        loading={loadingTimeSlots}
        colums={columns}
        page={params.pageNumber}
        rowsPerPage={params.pageSize}
        rows={timeSlots}
        count={timeSlots.length}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy="startDateTime"
        sortDirection="asc"
        sortGetValue={(x, orderBy) => {
          if (orderBy == "startDateTime")
            return format("time", x.startDateTime);
          else if (orderBy == "endDateTime")
            return format("time", x.endDateTime);
          else if (orderBy == "maxGroup") return x.maxGroup;
          else if (orderBy == "maxPersonPerGroup") return x.maxPersonPerGroup;
          else if (orderBy == "comments") return x.comments;
        }}
        viewSearch
        viewCreate
        onCreate={() => {
          setIsEdit(false);
          handleAddTimeSlot("create");
        }}
        viewEdit
        onEdit={(row) => {
          setShowTimeSlotModal(true);
          setFormData(row);
          setIsEdit(true);
        }}
        actions={[
          {
            component: (row) => (
              <DeleteButton
                onClick={() => {
                  setOpenDeleteModal(true);
                  setFormData(row);
                }}
              />
            ),
          },
        ]}
      />
      <AddTimeSlotModal
        params={params}
        setParams={setParams}
        open={showTimeSlotModal}
        handleClose={() => {
          setShowTimeSlotModal(false)
          refreshTimeSlots()
        }}
        isEdit={isEdit}
        // setIsEdit={false}
        formData={formData}
        refreshTimeSlots={() => refreshTimeSlots()}
      />
      <AlertDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deleting={isDeleting}
        onSubmit={handleDelete}
      />
    </div>
  );
};

export default TimeSlotTable;
