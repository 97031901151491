import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import { MyDialog } from "../../../../components/Dialog";
import { RestoLogo, TransparentLogo } from "../../../../components/Logo";
import { format } from "../../../../heplers/format";
import { getTranslation } from "../../../../heplers/translationHelper";
import { useParams } from "react-router-dom";

const styles = makeStyles(({ theme }) => ({
  descriptions: {
    width: "100%",
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem 0",
  },
}));

const SuccessMsgModal = ({ openModal, data, handleClose }) => {
  const params = useParams();
  const { primaryColor, secondaryColor } = params;
  const classes = styles();
  const purpose =
    data?.purposeOfReservation === 0
      ? "For Eating"
      : data?.purposeOfReservation;

  return (
    <MyDialog
      open={openModal}
      onClose={handleClose}
      title={getTranslation("Summary", "Summary", "Summary")}
      headerStyle={{
        backgroundColor: "#" + primaryColor,
      }}
    >
      <div
        style={{
          // width: "28rem",
          // height: "24rem",
          paddingBottom: "50px",
          paddingTop: "50px",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">
          {getTranslation("Thank you!", "Thank you!", "Thank you!")}
        </Typography>
        <br />

        <Box width="100%">
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>
                  {getTranslation(
                    "Number of Persons",
                    "Number of Persons",
                    "Number of Persons"
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{data?.persons}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>
                  {getTranslation(
                    "Number of Kids",
                    "Number of Kids",
                    "Number of Kids"
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{data?.kids}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>
                  {getTranslation(
                    "Arrival Date",
                    "Arrival Date",
                    "Arrival Date"
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{format("date", data?.reservationDate)}</Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography><strong>{getTranslation("Purpose of reservation", "Purpose of reservation", "Purpose of reservation")}</strong></Typography>

            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{purpose == 0 ? getTranslation("For Eating", "For Eating", "For Eating") : getTranslation("For Eating & Spending Time", "For Eating & Spending Time", "For Eating & Spending Time")}</Typography>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography><strong>{getTranslation("Arriving time", "Arriving time", "Arriving time")}</strong></Typography>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{format("time",data?.timeSlots[0]?.startDateTime)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>
                  {getTranslation("Name", "Name", "Name")}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                {data?.fullName ? data?.fullName : data?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>{getTranslation("Email", "Email", "Email")}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{data?.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <strong>
                  {getTranslation(
                    "Phone Number",
                    "Phone Number",
                    "Phone Number"
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{data?.phone}</Typography>
            </Grid>
            {data?.allergies ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography>
                  <strong>
                    {getTranslation(
                      "Allergies and Intolerance",
                      "Allergies and Intolerance",
                      "Allergies and Intolerance"
                    )}
                  </strong>
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>{data?.allergies}</Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </MyDialog>
  );
};

export default SuccessMsgModal;
