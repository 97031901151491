import React, { useState, useEffect } from "react";
import { getTranslation } from "../../../../heplers/translationHelper";
import styles from "../daysOffTable/styles.module.css";
import { useSnackbar } from "notistack";
import { CustomIframeDialog } from "../../../../layout/topbar/customIframeDialog";
import { Box, Grid, CardContent, Button } from "@material-ui/core";
import CardComponent from "../../../../components/Card";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { StyledToggleButtonGroup } from "../../../../components";
import authService from "../../../../utils/authUtils";

const IframeTab = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [primaryColor, setPrimaryColor] = useState("E7424C");
	const [secondaryColor, setSecondaryColor] = useState("ffffff");
	const [openCustomIframe, setOpenCustomIframe] = useState(true);
	const [codeToggle, setcodeToggle] = useState("code");
	const [isCopied, setIsCopied] = useState(false);

	const generatedCode = `https://${
		window.location.hostname
	}/public/user-reservation/${authService.getCustomerId()}/${primaryColor}/${secondaryColor}`;

	const handleCopyToClipboard = () => {
		const textField = document.createElement("textarea");
		textField.innerText = generatedCode;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		document.body.removeChild(textField);
		setIsCopied(true);
		enqueueSnackbar(
			getTranslation(
				"Code copied to clipboard",
				"Code copied to clipboard",
				"Code copied to clipboard"
			),
			{
				variant: "success",
			}
		);
	};

	useEffect(() => {
		if (isCopied) {
			const timeoutId = setTimeout(() => {
				setIsCopied(false);
			}, 2000);

			return () => clearTimeout(timeoutId);
		}
	}, [isCopied]);

	const renderContent = () => {
		switch (codeToggle) {
			case "code":
				return (
					<Grid item sm={12} md={12} lg={12}>
						<CustomIframeDialog
							open={openCustomIframe}
							onClose={() => setOpenCustomIframe(false)}
						/>
					</Grid>
				);
			case "url":
				return (
					<Grid item sm={12} md={12} lg={12}>
						<label>
							{getTranslation(
								"Generated Code",
								"Generated Code",
								"Generated Code"
							) + ":"}
						</label>
						<Box border={"1px solid gray"} p={1} minHeight="100px">
							<pre>{generatedCode}</pre>
						</Box>
						<Button
							onClick={handleCopyToClipboard}
							color="primary"
							variant="contained"
							style={{ marginTop: "10px" }}
						>
							{isCopied
								? getTranslation("Copied!", "Copied!", "Copied!")
								: getTranslation(
										"Copy generated code",
										"Copy generated code",
										"Copy generated code"
								  )}
						</Button>
					</Grid>
				);
			default:
				return null;
		}
	};

	return (
		<div className={styles.tabWrapper}>
			<CardComponent
				title={getTranslation("frame", "frame", "frame")}
				style={{ marginTop: "20px" }}
			>
				<Box display="flex" justifyContent="center">
					<StyledToggleButtonGroup value={codeToggle} color="success">
						<ToggleButton
							color="secondary"
							value="code"
							onClick={() => setcodeToggle("code")}
						>
							{getTranslation("Code", "Code", "Code")}
						</ToggleButton>

						<ToggleButton value="url" onClick={() => setcodeToggle("url")}>
							{getTranslation("URL", "URL", "URL")}
						</ToggleButton>
					</StyledToggleButtonGroup>
				</Box>
				<CardContent>{renderContent()}</CardContent>
			</CardComponent>
		</div>
	);
};
export default IframeTab;
