import {
  LOADING_CUSTOMERS,
  SET_CUSTOMERS,
  SET_CUSTOMER_PAGE_NUMBER,
  SET_CUSTOMER_TOTAL_COUNT,
} from "../constants/types";

const initialState = {
  totalCount: 0,
  pageNumber: 0,
  customers: [],
  loadingCustomers: false,
};

const customerManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case LOADING_CUSTOMERS:
      return {
        ...state,
        loadingCustomers: action.payload,
      };
    case SET_CUSTOMER_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_CUSTOMER_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    default:
      return state;
  }
};

export default customerManagementReducer;
