import React from "react";
import { useParams } from "react-router-dom";
import CurrentReservation from "./currentReservation";

const Reservations = () => {
  const params = useParams();
  const tab = params.tab || "current";
  return <>{tab === "current" && <CurrentReservation />}</>;
};

export default Reservations;
