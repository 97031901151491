import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const ButtonWithLoading = ({
	title,
	size,
	variant,
	loading,
	color,
	textColor, // new prop for text color
	...rest
}) => {
	return (
		<Button
			size={size}
			color={color}
			variant={variant}
			disabled={loading}
			startIcon={
				loading ? (
					<CircularProgress thickness={3} size={22} color={color} />
				) : null
			}
			style={{ color: textColor || "inherit" }}
			{...rest}
		>
			{title}
		</Button>
	);
};

export default ButtonWithLoading;
