import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Sidebar from "./sidebar";
import TopBar from "./topbar";
import Breadcrumbs from "../components/BreadCrumbs";
import useStyles from "../assests/styles/layout/main";
import clsx from "clsx";
import { getLanguage } from "../actions";

export default function DashboardLayout(props) {
	const classes = useStyles();
	const [navOpen, setNavOpen] = useState(true);
	const [loadingTranslation, setLoadingTranslation] = useState(true);
	useEffect(() => {
		if (window.innerWidth < 1500) setNavOpen(false);
		else setNavOpen(true);
	}, []);

	return (
		<div className={classes.root}>
			<TopBar
				className=""
				navOpen={navOpen}
				handleDrawerOpen={() => setNavOpen(true)}
			/>
			<Sidebar setOpenMobile={setNavOpen} openMobile={navOpen} />
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: navOpen,
				})}
			>
				<Box mb={2} className={classes.breadcrums}>
					<Breadcrumbs />
				</Box>
				{props.children}
			</main>
		</div>
	);
}
