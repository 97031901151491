import { ToggleButtonGroup } from "@material-ui/lab";
import { styled } from "@material-ui/styles";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(1),
     borderRadius:'8px',
     backgroundColor:theme.palette.common.white,
     color:theme.palette.common.black,
     border:0,
     boxShadow:'0px 1px 10px rgba(0, 0, 0, 0.15)',
    //  padding:'8px',
     "&:hover":{
      backgroundColor:theme.palette.primary.main,
      color:theme.palette.common.white,
     }
    },
    '& .Mui-selected' : {
      backgroundColor:theme.palette.primary.main,
     color:theme.palette.common.white,
    }
  }));