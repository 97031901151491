import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import DateFnsUtils from "@date-io/date-fns";
// import { TextField } from "@material-ui/core";

// const DatePickerWithBorder = ({ value, handleChange, label }) => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DesktopDatePicker
//         label={label}
//         inputFormat="MM/DD/YYYY"
//         value={value}
//         onChange={handleChange}
//         renderInput={(params) => <TextField {...params} />}
//       />
//     </LocalizationProvider>
//   );
// };

export const DatePicker = ({
  label,
  value,
  color,
  error,
  fullWidth,
  required,
  hasBorder,
  onChange,
  renderDay,
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        disableToolbar
        variant="outlined"
        format="dd-MM-yyyy"
        size="small"
        id="From-Date-inline"
        sx={{ color: "white" }}
        label={label}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": label,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        error={error}
        {...rest}
        renderDay={renderDay}
      />
    </MuiPickersUtilsProvider>
  );
};
