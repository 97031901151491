import { combineReducers } from "redux";
import auth from "./authReducer";

import breadcrumbs from "./breadcrumbReducer";
import customerManagementReducer from "./customersReducers";
import maxCapacityReducer from "./maxCapacityReducer";
import submenu from "./submenu";
import userReservationReducer from "./userReservationReducer";
import theme from "./themeReducer";

export default combineReducers({
  auth,
  breadcrumbs,
  submenu,
  userReservation: userReservationReducer,
  customerManagement: customerManagementReducer,
  maxCapacity: maxCapacityReducer,
  theme,
  
});
