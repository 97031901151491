import { Box, Grid, Button } from "@material-ui/core";
import { useState, useEffect } from "react";
import { MyDialog } from "../../components/Dialog";
import { getTranslation } from "../../heplers/translationHelper";
import authService from "../../utils/authUtils";
import { useSnackbar } from "notistack";

export const CustomIframeDialog = ({ open, onClose, onSubmit }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [primaryColor, setPrimaryColor] = useState("E7424C");
	const [secondaryColor, setSecondaryColor] = useState("ffffff");
	const [isCopied, setIsCopied] = useState(false);

	const generateIframeCode = () => {
		return `<iframe src="https://${
			window.location.hostname
		}/public/user-reservation/${authService.getCustomerId()}/${primaryColor}/${secondaryColor}/ifarame" width="500px" height="600px"></iframe>`;
	};

	const handleCopyToClipboard = () => {
		const iframeCode = generateIframeCode();
		navigator.clipboard.writeText(iframeCode);
		setIsCopied(true);
		enqueueSnackbar(
			getTranslation(
				"Code copied to clipboard",
				"Code copied to clipboard",
				"Code copied to clipboard"
			),
			{
				variant: "success",
			}
		);
	};

	useEffect(() => {
		if (isCopied) {
			const timeoutId = setTimeout(() => {
				setIsCopied(false);
			}, 2000);

			return () => clearTimeout(timeoutId);
		}
	}, [isCopied]);

	return (
		<div>
			<Grid container spacing={3} direction="row">
				<Grid item xs="12" md="2" lg="2" xl="2">
					<label>
						{getTranslation("Primary Color", "Primary Color", "Primary Color") +
							":"}
					</label>
				</Grid>
				<Grid item xs="12" md="10" lg="10" xl="10">
					<input
						type="color"
						value={"#" + primaryColor}
						onChange={(e) => setPrimaryColor(e.target.value.replace("#", ""))}
					/>
				</Grid>
				<Grid item xs="12" md="2" lg="2" xl="2">
					<label>
						{getTranslation(
							"Secondary Color",
							"Secondary Color",
							"Secondary Color"
						) + ":"}
					</label>
				</Grid>
				<Grid item xs="12" md="10" lg="10" xl="10">
					<input
						type="color"
						value={"#" + secondaryColor}
						onChange={(e) => setSecondaryColor(e.target.value.replace("#", ""))}
					/>
				</Grid>

				<Grid item xs="12" md="2" lg="2" xl="2">
					<label>
						{getTranslation(
							"Generated Code",
							"Generated Code",
							"Generated Code"
						) + ":"}
					</label>
				</Grid>
				<Grid item xs="12" md="8" lg="8" xl="8">
					<Box border={"1px solid gray"} p={1} width={1} minHeight="100px">
						<pre>{generateIframeCode()}</pre>
					</Box>
					<br />
					<Button
						variant="contained"
						color="primary"
						onClick={handleCopyToClipboard}
					>
						{isCopied
							? getTranslation("Copied", "Copied", "Copied") + "!"
							: getTranslation(
									"Copy generated code",
									"Copy generated code",
									"Copy generated code"
							  )}
					</Button>
				</Grid>

				<Grid item xs="12" md="12" lg="12" xl="12">
					<label>{getTranslation("Preview", "Preview", "Preview") + ":"}</label>
				</Grid>
				<Grid item xs="12" md="2" lg="2" xl="2"></Grid>
				<Grid item xs="12" md="8" lg="8" xl="8">
					<iframe
						src={
							"https://" +
							window.location.hostname +
							"/public/user-reservation/" +
							authService.getCustomerId() +
							"/" +
							primaryColor +
							"/" +
							secondaryColor +
							"/iframe"
						}
						width="100%"
						height="500px"
					></iframe>
				</Grid>
			</Grid>
		</div>
	);
};
