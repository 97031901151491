import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const MyTimePicker = ({
  label,
  value,
  error,
  required,
  onChange,
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        fullWidth
        // disableToolbar
        showToolbar
        variant="inline"
        size="small"
        label={label}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": label,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        error={error}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
