import React, { useState } from "react";
import { MyDialog } from "../../../../components/Dialog";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import styles from "./styles.module.css";
import { DatePicker } from "../../../../components/DatePicker";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@material-ui/lab";
import { daysConstant } from "../../../../constants/general";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { Post } from "../../../../actions";
import { Upsert_DaysOff_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";

const AddDaysOff = ({
	openDialog,
	handleClose,
	isEdit,
	selectedFormData,
	setSelectedFormData,
	setParams,
	params,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [selectedTab, setSelectedTab] = useState(0);

	// useEffect(() => {
	//   consol
	//   if (selectedFormData.endDate !== null) {
	//     setSelectedTab(1);
	//   } else {
	//     setSelectedTab(0);
	//   }
	// }, [selectedFormData]);

	const validationSchema = Yup.object().shape({
		startDate: Yup.date().required("Please select a date"),
		day: Yup.string(),
		comments: Yup.string(),
	});

	const handleAlignment = (event, newAlignment) => {
		setSelectedTab(newAlignment);
	};

	const onSubmit = (values, actions) => {
		actions.setSubmitting(true);

		Post(
			values,
			Upsert_DaysOff_URL,
			null,
			(res) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Added Successfully",
						"Added Successfully",
						"Added Successfully"
					),
					{
						variant: "success",
					}
				);
				handleClose(false);
				setParams({ ...params, pageNumber: 0 });
			},
			(err) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Something went wrong",
						"Something went wrong",
						"Something went wrong"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<MyDialog
			open={openDialog}
			onClose={handleClose}
			title={
				isEdit
					? getTranslation("Edit Days Off", "Edit Days Off", "Edit Days Off")
					: getTranslation("Add Days Off", "Add Days Off", "Add Days Off")
			}
		>
			<div className={styles.modalContent}>
				<div className={styles.formCont}>
					<Formik
						initialValues={selectedFormData}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							isSubmitting,
							setFieldValue,
							handleSubmit,
						}) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={4}>
									<div className={styles.tabsWrapper}>
										<div>
											<ToggleButtonGroup
												fullWidth
												exclusive
												color="primary"
												value={values.dayOffType}
												onChange={(e, val) => {
													if (val || val == 0) setFieldValue("dayOffType", val);
												}}
											>
												<ToggleButton value={0} color="secondary">
													{getTranslation("Days", "Days", "Days")}
												</ToggleButton>
												<ToggleButton value={1}>
													{getTranslation("Period", "Period", "Period")}
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
									</div>
									<Grid item sm={12} md={12} lg={12}>
										<DatePicker
											required
											size="small"
											name="startDate"
											id="startDate"
											label={getTranslation(
												"Start Date",
												"Start Date",
												"Start Date"
											)}
											value={values.startDate}
											onChange={(val) => {
												const day = new Date(val).getDay();
												setFieldValue("day", day);
												setFieldValue("startDate", val);
											}}
											error={touched.startDate && errors.startDate}
										/>
									</Grid>
									{values.dayOffType === 1 && (
										<Grid item sm={12} md={12} lg={12}>
											<DatePicker
												size="small"
												name="endDate"
												id="endDate"
												label={getTranslation(
													"End Date",
													"End Date",
													"End Date"
												)}
												value={values.endDate}
												onChange={(val) => setFieldValue("endDate", val)}
												error={touched.endDate && errors.endDate}
											/>
										</Grid>
									)}
									{/* <Grid item sm={12} md={12} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="day"
                      label={getTranslation("Day", "Day", "Day")}
                      name="day"
                      type="text"
                      size="small"
                      value={values.day}
                      onChange={handleChange}
                      error={touched.day && Boolean(errors.day)}
                      helperText={touched.day && errors.day}
                    />
                  </Grid> */}
									<Grid item sm={12} md={12} lg={12}>
										<TextField
											variant="outlined"
											fullWidth
											id="comments"
											label={getTranslation("Comments", "Comments", "Comments")}
											name="comments"
											type="text"
											size="small"
											value={values.comments}
											onChange={handleChange}
											error={touched.comments && Boolean(errors.comments)}
											helperText={touched.comments && errors.comments}
										/>
									</Grid>
								</Grid>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										justifyContent: "flex-end",
										marginTop: "1rem",
									}}
								>
									<ButtonWithLoading
										title={getTranslation("Submit", "Submit", "Submit")}
										type="submit"
										variant="contained"
										color="primary"
										textColor="white"
										loading={isSubmitting}
									/>
								</Box>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</MyDialog>
	);
};

export default AddDaysOff;
