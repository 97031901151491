/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Get } from "../../actions";
import { Get_All_Customers_URL } from "../../constants/apiUrls";
import AddCustomer from "./components/addCustomer";
import CustomersTable from "./components/customersTable";
import { Helmet } from "react-helmet";
import { getTranslation } from "../../heplers/translationHelper";

const CustomerManagement = () => {
  const [queryParams, setQueryParams] = useState({
    pageSize: 10,
    pageNumber: 0,
    searchString: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    // allergies: "",
    subscribeForNews: false,
  });
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [stat, setStat] = useState({
    totalCount: 0,
  });
  const [customers, setCustomers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getAllCustomers = (payload) => {
    setIsLoadingCustomers(true);
    Get(
      payload,
      Get_All_Customers_URL,
      null,
      (res) => {
        const { data, ...rest } = res?.data;
        setCustomers(data);
        setStat(rest);
        setIsLoadingCustomers(false);
      },
      (err) => {
        setIsLoadingCustomers(false);
        enqueueSnackbar(err.data, {
          variant: "error",
        });
      }
    );
  };

  useEffect(() => {
    getAllCustomers({
      pageSize: queryParams.pageSize,
      pageNumber: queryParams.pageNumber,
      searchString: queryParams.searchString,
    });
  }, [queryParams]);

  return (
    <div>
        <Helmet>
        <title>
          {getTranslation("Customers","Customers","Customers")}
        </title>
      </Helmet>
      <AddCustomer
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        params={queryParams}
        setParams={setQueryParams}
        formData={formData}
        setFormData={setFormData}
      />
      <CustomersTable
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        isLoadingCustomers={isLoadingCustomers}
        stat={stat}
        customers={customers}
        formData={formData}
        setFormData={setFormData}
        setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CustomerManagement;
