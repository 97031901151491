import { Formik } from 'formik';
import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import { getTranslation } from '../../../../heplers/translationHelper';
import { MyDialog } from '../../../../components/Dialog';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';


function RestoCapacityDialog(props) {

  const { handleOpen, handleClose, selectedPerons } = props;
  return (
    <>
      <MyDialog
        open={handleOpen}
        onClose={handleClose}
        title={getTranslation("Reservation Unavailable", "Reservation Unavailable", "Reservation Unavailable")
        }
      >
        <Box marginTop={"1rem"} marginBottom={"1rem"} sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
        >
          <DialogContent>
            <Typography>
              {getTranslation("Sorry reservatin is not available for selected Persons. Do you want to send special request?",
                "Sorry reservatin is not available for selected Persons. Do you want to send special request?",
                "Sorry reservatin is not available for selected Persons. Do you want to send special request?")}
            </Typography>

            <Typography>
              {getTranslation(
                "mail us at:",
                "mail us at:",
                "mail us at:"
              )
              }
              <a href='mailto:hello@domaine-charmilles.be'>hello@domaine-charmilles.be</a>
            </Typography>
          </DialogContent>
        </Box>
      </MyDialog>
    </>
  )
}


export default RestoCapacityDialog;