import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Card, CardContent, Grid } from "@material-ui/core";
import Moment from "moment";
import _ from "lodash";
import { getTranslation } from "../heplers/translationHelper";
import { ReportLayout, reportStyles } from "../views/report/ReportLayout";
import { format } from "../heplers/format";
import CutleryIcon from "./CurtleryIcon";
import PeopleIcon from "./PeopleIcon";

const PrintDialog = React.forwardRef(({ rows, params }, ref) => {
  const classes = reportStyles();
  const noData = typeof rows === "undefined";
  const newPercent = rows?.maxCapacity ? ((rows?.numberOfPersons / rows?.maxCapacity) * 100) : 0;
  return (
    <ReportLayout
      ref={ref}
      noData={noData}
      //title={getTranslation("Reservations", "Reservations", "Reservations")}
      title={"Reservations"}
    >
      <div>
        <Card >
          <CardContent>
            <Grid container spacing={4}>
              {/* Filter Information */}
              <Grid item xs={1} sm={1} md={1}></Grid>
              <Grid item xs={5} sm={5} md={5}>
                <Box display={"flex"}>
                  <Typography className={classes.titleStyles}>
                    {getTranslation(
                      "Reservation Date",
                      "Reservation Date",
                      "Reservation Date"
                    )}
                    :
                  </Typography>
                  <Typography className={classes.titleStyles}>
                    {format("date", params?.selectedDate)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} sm={1} md={1}></Grid>
              <Grid item xs={5} sm={5} md={5}>
                <Box display={"flex"}>
                  <Typography className={classes.titleStyles}>
                    {getTranslation("Day-Time", "Day-Time", "Day-Time")} : 
                  </Typography>
                  <Typography className={classes.titleStyles}>
                    {params?.selectedDayTime === 0 ? getTranslation("Day", "Day", "Day") : getTranslation("Evening", "Evening", "Evening")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

          </CardContent>
        </Card>
        <br />

        <Grid container
          style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "1rem",
          }}
        >
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={5} sm={5} md={5}>
            <Typography className={classes.titleStyles}>
              {getTranslation("Percentage", "Percentage", "Percentage")}:{" "}
              {`${newPercent.toFixed(2)} %`}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={5} sm={5} md={5}>
            <Typography className={classes.titleStyles}>
              {getTranslation(
                "Total Capacity",
                "Total Capacity",
                "Total Capacity"
              )}
              : {rows?.maxCapacity}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={5} sm={5} md={5}>
            <Typography className={classes.titleStyles}>
              {getTranslation(
                "Number of Persons",
                "Number of Persons",
                "Number of Persons"
              )}
              : {rows?.numberOfPersons}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={5} sm={5} md={5}>
            <Typography className={classes.titleStyles}>
              {getTranslation(
                "Number of Groups",
                "Number of Groups",
                "Number of Groups"
              )}
              : {rows?.numberOfGroups}
            </Typography>
          </Grid>
        </Grid>
        <br />

        <div>
          <table className={classes.table}>
            <thead>
              <tr>
                <th width="10%">
                  <Typography className={classes.titleStyles}>
                    {getTranslation("Title", "Title", "Title")}
                  </Typography>
                </th>
                <th width="10%">
                  <Typography className={classes.titleStyles}>
                    {getTranslation("Time", "Time", "Time")}
                  </Typography>
                </th>
                <th width="10%">
                  <Typography className={classes.titleStyles}>
                    {getTranslation(
                      "# Persons",
                      "# Persons",
                      "# Persons"
                    )}
                  </Typography>
                </th>
                <th width="10%">
                  <Typography className={classes.titleStyles}>
                    {getTranslation("Allergies", "Allergies", "Allergies")}
                  </Typography>
                </th>
                <th width="10%">
                  <Typography className={classes.titleStyles}>
                    {getTranslation(
                      "Table Reserved",
                      "Table Reserved",
                      "Table Reserved"
                    )}
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows?.data?.map((item) => (
                <tr>
                  <td align="center" width="10%">
                    <Typography className={classes.subtitle}>
                      {item?.title}
                    </Typography>
                  </td>
                  <td align="center" width="10%">
                    <Typography className={classes.subtitle}>
                      {format("time", item.timeSlot?.startDateTime)}
                    </Typography>
                  </td>
                  <td align="center" width="10%">
                    <Typography className={classes.subtitle}>
                      {item?.persons}
                    </Typography>
                  </td>
                  <td align="center" width="10%">
                    <Typography className={classes.subtitle}>
                      {item.allergies || "N/A"}
                    </Typography>
                  </td>
                  <td align="center" width="10%">
                    <Typography className={classes.subtitle}>
                      {item.tableReserved}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* <tfoot>
                <tr>
                  <td colSpan={4} style={{ height: "14px" }}></td>
                </tr>
                <tr>
                  <td
                    align={"center"}
                    style={{
                      background: "#233044",
                      color: "#fff",
                    }}
                  >
                    <Typography className={classes.endCards}>
                      {getTranslation(" Total ", " Totale ", " Gesamt ")}
                    </Typography>
                  </td>
                  <td
                    style={{
                      background: "#EB5C3E",
                      color: "#fff",
                    }}
                  ></td>
                  <td
                    align="right"
                    style={{
                      background: "#EB5C3E",
                      color: "#fff",
                    }}
                  >
                    <Typography className={classes.endCards}>
                      &euro; {_.sumBy(rows?.data, "dueGenerated").toFixed(2)}
                    </Typography>
                  </td>
                  <td
                    align="right"
                    style={{
                      background: "#EB5C3E",
                      color: "#fff",
                    }}
                  >
                    <Typography className={classes.endCards}>
                      &euro; {_.sumBy(rows?.data, "amountDeposit").toFixed(2)}
                    </Typography>
                  </td>
                  {/* <td align="right" style={{
                background: "#EB5C3E",
                color: "#fff",
              }}>
                <Typography className={classes.endCards}>
                  &euro;
                  {format("number", _.sum(rows?.data,"amountDeposit"))}
                </Typography>
              </td> */}
            {/* </tr>
              </tfoot>  */}
            {/* <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <Box
                item
                style={{
                  background: "#233044",
                  color: "#fff",
                }}
                xs={2}
                sm={2}
                md={2}
                lg={2}
                className={classes.endCards}
              >
                <Typography>Total</Typography>
              </Box>
            </td>
            <td>
              <Box
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                className={classes.endCards}
                style={{
                  background: "#EB5C3E",
                  color: "#fff",
                }}
              >
                <Typography>&euro; 00.00</Typography>
              </Box>
            </td>
          </tr> */}
          </table>
        </div>
      </div>
    </ReportLayout>
  );
});

export default PrintDialog;
